import React, { useState } from "react";
import NavBar from "../NavBar";
import "./Styles/Form.css";
import axios from "axios";
import { useHistory } from "react-router-dom";

function Thought() {
    const [thought, setThought] = useState();
    const navigate = useHistory();

    // Submit the data of thought form
    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios
                .post("https://chandrodaymsmd.org:5500/chandrodaymsmd/thought/add", {
                    thought,
                })
                .then(alert("Data Added Succefully!"))
                .then(navigate.push("/home"));
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <NavBar />
            <section className="container">
                <div className="w-50 login-box mt-5">
                    <form
                        action=""
                        encType="multipart/form-data"
                        className="m-3"
                        onSubmit={onSubmit}
                    >
                        <h3 className="login-title">Thought-Form</h3>
                        <div className="mb-3">
                            <label for="though" class="form-label">
                                Thought
                            </label>
                            <input
                                type="text"
                                value={thought}
                                className="form-control"
                                id="though"
                                onChange={(e) => setThought(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </form>
                </div>
            </section>
        </div>
    )
}

export default Thought