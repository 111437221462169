import React, { useState, useEffect } from "react";
import NavBar from "../NavBar";
import "./Styles/Form.css";
import axios from "axios";
import { useHistory } from "react-router-dom";

function SubMenuForm() {
  const [submenuName, setSubmenuName] = useState("");
  const [menuDetails, setMenuDetails] = useState([]);
  const [menuid, setMenuid] = useState("");
  const [isDataFetch, setIsDataFetch] = useState(false);
  const navigate = useHistory();

  // Get Menu Detailes

  const handleChange = (event) => {
    setMenuid(event.target.value);
  };

  // Submit the data of sub menu form

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post("https://chandrodaymsmd.org:5500/chandrodaymsmd/submenu/addSubmenu", {
          submenuName,
          parent_id: menuid,
        })
        .then(alert("Data Added Succefully!"))
        .then(navigate.push("/home"));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleMenuDetails = async () => {
      try {
        axios
          .get("https://chandrodaymsmd.org:5500/chandrodaymsmd/menu/getMenu")
          .then((result) => setMenuDetails(result.data))
          .then(() => setIsDataFetch(true))
          .catch((err) => console.log(err));
      } catch (error) {
        console.log(error);
      }
    };

    handleMenuDetails();
  }, []);

  return (
    <div>
      <NavBar />
      <section className="container">
        <div className="w-50 login-box mt-5">
          <form
            action=""
            encType="multipart/form-data"
            className="m-3"
            onSubmit={onSubmit}
          >
            <div className="form-group mb-3">
              <h3 className="login-title">Sub-Menu-Form</h3>
              <div className="mb-3">
                <label class="form-label">Select Menu</label>
                {/* selecting parent id for perticular submenu */}
                <select
                  name="SubMenu"
                  className="form-control"
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Name</option>
                  {menuDetails.map((data, i) => (
                    <option value={data._id} key={i}>
                      {data.menuName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label for="sub_heading" class="form-label">
                Sub Heading
              </label>
              <input
                type="text"
                value={submenuName}
                className="form-control"
                id="sub_heading"
                aria-describedby="emailHelp"
                onChange={(e) => setSubmenuName(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </section>
    </div>
  );
}

export default SubMenuForm;
