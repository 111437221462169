import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .course-filter {
    background: ${colors.bg2};
    padding: 50px 0 56px;

    .sec-title {
      h4 {
        color: ${colors.black1};
        line-height: 35px;
        font-weight: 600;
        max-width: 550px;
        margin: auto;
        margin-bottom: 42px;

        @media (max-width: 575px) {
          margin-bottom: 15px;
          font-size: 20px;
        }
      }
    }

    .filter-btns {
      ul.filter-btn-list {
        background-color: #ffffff;
        display: inline-block;
        border-radius: 5px;
        box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
        margin-bottom: 30px;

        li {
          font-size: 14px;
          color: ${colors.black2};
          font-weight: 500;
          padding: 10px 20px;
          cursor: pointer;
          border-radius: 5px;
          text-transform: uppercase;

          @media (max-width: 767px) {
            font-size: 13px;
            padding: 8px 12px;
          }
        }

        li.active {
          background: ${colors.gr_bg};
          color: #ffffff;
        }

        li:hover {
          background: ${colors.gr_bg};
          color: #ffffff;
        }

        .list-inline-item:focus {
          background: ${colors.gr_bg};
          color: #ffffff;
        }

        .list-inline-item--active:after {
          background: ${colors.gr_bg};
          color: #ffffff;
        }

        @media (max-width: 575px) {
          margin-bottom: 35px;
        }
      }
    }

    .filter-items {
      .course-item {
        transition: all 0.2s ease;
        margin-bottom: 30px;
        border: 1px solid #e6f0ec;
        border-radius: 5px;
        transition: transform .3s;
        cursor: pointer;

        .course-image {
          width: 100%;
          height: 220px;
          border-radius: 5px 5px 0 0;
          position: relative;

            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
        }

        .course-content {
          background: #fff;
          padding: 20px 22px;
          border-radius: 0 0 5px 5px;

          h6.heading {
            a {
              color: ${colors.black1};
              font-weight: 600;
              display: inline-block;
              margin-bottom: 5px;

              &:hover {
                color: ${colors.green};
              }
            }
          }

          .course-face {
            height:120px;
            overflow-y: overlay;
            
            p.desc {
            font-size: 14px;
            color: ${colors.text3};
            line-height: 25px;
            padding-bottom: 10px;
            margin-bottom: 12px;
            margin-right: 8px;
            text-align: justify;
          }
            &::-webkit-scrollbar-track
            {
              -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5);
              background-color: #F5F5F5;
            }

            &::-webkit-scrollbar
            {
              width: 2px;
              background-color: #F5F5F5;
            }

            &::-webkit-scrollbar-thumb
            {
              background-color: #bababa;
            }
          }
        }
        &:hover {
          box-shadow: 0px 0px 10px 2px #686666;
          transform: scale(1.05);
        }
      }
    }

    .viewall-btn {
      a {
        font-size: 15px;
        color: #fff;
        background: ${colors.gr_bg};
        display: inline-block;
        width: 200px;
        height: 48px;
        text-transform: uppercase;
        font-weight: 500;
        text-align: center;
        padding: 14px;
        border-radius: 5px;
        margin-top: 20px;

        &:hover {
          background: ${colors.gr_bg2};
        }

        @media (max-width: 575px) {
          font-size: 13px;
          width: 170px;
          height: 42px;
          padding: 12px;
          margin-top: 10px;
        }
      }
    }

    @media (max-width: 767px) {
      padding: 25px 0 40px;
    }
    .iframeSettings {
      width: 100%;
      height: 100%;
    }
  }
`;
