import React, { useEffect } from "react";
import Datas from "../data/help-area/help-area.json";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/homeContact.js";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function HelpArea() {
  useEffect(() => {
    const form = document.getElementById("form1");
    const name = document.getElementById("name1");
    const email = document.getElementById("email1");
    const subject = document.getElementById("subject1");

    form.addEventListener("submit", formSubmit);

    function formSubmit(e) {
      e.preventDefault();

      const nameValue = name.value.trim();
      const emailValue = email.value.trim();
      const subjectValue = subject.value.trim();

      if (nameValue === "") {
        setError(name, "Name can't be blank");
      } else {
        setSuccess(name);
      }

      if (emailValue === "") {
        setError(email, "Email can't be blank");
      } else if (!isEmail(emailValue)) {
        setError(email, "Not a valid email");
      } else {
        setSuccess(email);
      }

      if (subjectValue === "") {
        setError(subject, "Subject can't be blank");
      } else {
        setSuccess(subject);
      }
    }

    function setError(input, message) {
      const formControl = input.parentElement;
      const errorMsg = formControl.querySelector(".input-msg1");
      formControl.className = "form-control error";
      errorMsg.innerText = message;
    }

    function setSuccess(input) {
      const formControl = input.parentElement;
      formControl.className = "form-control success";
    }

    function isEmail(email) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
    }
  });

  return (
    <Styles>
      {/* Help Area */}
      <section
        className="home-contact-area"
        style={{
          // backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${Datas.backgroundImage})`,  
        }}
      >
        <Container style={{ maxWidth: "90%" }}>
          <Row>
            <Col md="12">
              <div className="sec-title text-center">
                <h4>{Datas.secTitle}</h4>
              </div>
            </Col>
            <Col md="12">
              <form id="form1" className="form">
                <div style={{ textAlign: "center" }}>
                  <p className="form-control" style={{ marginBottom: "15px" }}>
                    <font size="4" style={{ color: "white" }}>
                    "Chhattisgarh Chandranahu Shikshan Samiti's Remarkable 6-Year Journey: Empowering Over 1000 Students through Education"
                    </font>
                  </p>
                  <p className="form-control" style={{ marginBottom: "15px" }}>
                    <font size="5" style={{ color: "white" }}>
                    Fostering Change and Empowering Young Minds: Run's Remarkable Impact
                    </font>
                  </p>
                  <p className="form-control" style={{ marginBottom: "15px" }}>
                    <font size="4" style={{ color: "white" }}>
                    In just six transformative years, Chandroday Public School has empowered over 1000 students, becoming a beacon of hope for countless young minds. With a dedicated team of educators and innovative teaching methods, the organisation leaves an indelible mark on academic and personal growth. Beyond excellence in education, CPS creativity and core values, nurturing well-rounded, confident citizens for a promising future. Their unwavering commitment to holistic education enriches the community, setting an inspiring example for accessible and empowering education.
                    </font>
                  </p>
                </div>
                <Col md="12" className="text-center">
                  <button><Link to={"/contact"} style={{color:"#fff"}}>Apply Now</Link></button>
                </Col>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default HelpArea;
