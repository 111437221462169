import React, { Component } from "react";
import Swiper from "react-id-swiper";
import { Styles } from "./styles/heroSlider.js";
import axios from "axios";

class HeroSlider extends Component {
  state = {
    slider: [],
  };

  sliderImage = async () => {
    await axios
      .get("https://chandrodaymsmd.org:5500/chandrodaymsmd/slider/get")
      .then((result) => {
        this.setState({ slider: result.data.data });
        // console.log(result.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.sliderImage();
  }

  render() {
    const settings = {
      slidesPerView: 1,
      loop: true,
      speed: 3000,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      watchSlidesVisibility: true,
      effect: "fade",
      navigation: {
        nextEl: ".slider-button-next",
        prevEl: ".slider-button-prev",
      },
      renderPrevButton: () => (
        <div className="swiper-btn slider-button-prev">
          <i className="flaticon-arrow-left-th"></i>
        </div>
      ),
      renderNextButton: () => (
        <div className="swiper-btn slider-button-next">
          <i className="flaticon-arrow-right-th"></i>
        </div>
      ),
    };
    // console.log(this.state.slider);

    return (
      <Styles>
        {/* Hero Slider */}
        <section className="hero-slider-area">
          {this.state.slider.length === 0 ?

            <div></div>
            :
            <Swiper {...settings}>
              {this.state.slider.map((data, i) => {
                {/* console.log(data._id); */ }
                return (
                  <div className="slider-item" key={data._id}>
                    <div className="image-container">
                      <img style={{ height: "100%", width: "100%" }}
                        src={`https://chandrodaymsmd.org:5500/${data.sliderImage}`}
                        // src={slider1}
                        // src={`/assets/slider/${data.sliderImage}`}
                        className="slider-image"
                        alt={"Slider"}
                      />
                    </div>
                  </div>
                );
              })}
            </Swiper>
          }

        </section>
      </Styles>
    );
  }
}

export default HeroSlider;
