import React, { useRef, useState } from "react";
import "./Styles/Form.css";
import NavBar from "../NavBar";
import SideBar from "../SideBar";
import axios from "axios";
import { useHistory } from "react-router-dom";
import JoditEditor from "jodit-react";

function MenuForm() {
  const navigate = useHistory();
  const [menuName, setMenuName] = useState("");
  const [heading, setHeading] = useState("");
  const [file, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [check, setCheck] = useState(false);
  console.log(check);

  const editor = useRef(null);

  // Submit the data of menu form
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("menuName", menuName);
    formData.append("contentHeading", heading);
    formData.append("content", description);
    formData.append("file", file);

    await axios
      .post("https://chandrodaymsmd.org:5500/chandrodaymsmd/menu/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => alert("Data Added Succesfully!"))
      .then(navigate.push("/home"))
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <NavBar />
      <div style={{ marginBottom: "32px" }}>
        <div className="w-50 login-box mt-5">
          <form onSubmit={handleSubmit} className="container form">
            <h3 className="login-title">Menu-Form</h3>
            <div className="mb-3">
              <label className="form-label" htmlFor="inputMenuName">
                Menu Name
              </label>
              <input
                name="menuName"
                type="text"
                className="form-control"
                id="inputMenuName"
                aria-describedby="emailHelp"
                value={menuName}
                onChange={(e) => setMenuName(e.target.value)}
                required
              />
            </div>
            <div class="form-check mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                onChange={() => setCheck(!check)}
              />
              <label class="form-check-label" for="flexCheckDefault">
                Check to insert content
              </label>
            </div>
            {check ? (
              <div className="">
                <div className="mb-3">
                  <label htmlFor="exampleInputHeading" class="form-label">
                    Content Heading
                  </label>
                  <input
                    type="text"
                    value={heading}
                    class="form-control"
                    id="exampleInputHeading"
                    aria-describedby="emailHelp"
                    onChange={(e) => setHeading(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputImage" class="form-label">
                    Select Image
                  </label>
                  <div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputDescription" class="form-label">
                    Description
                  </label>
                  <JoditEditor
                    ref={editor}
                    value={description}
                    onChange={(newContent) => setDescription(newContent)}
                    style={{ height: "500px" }}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
export default MenuForm;
