import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Styles } from "./styles/account.js";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authAction } from "../../Redux/Store";
import Header from "../../components/Header";

function Login() {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const [msg, setMsg] = useState("");

  const onChangeInput = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post("https://chandrodaymsmd.org:5500/chandrodaymsmd/user/login", {
          UserName: inputs.email,
          Password: inputs.password,
        })
        .then((result) => {
          // console.log(result.data);
          dispatch(authAction.login(result.data));
          localStorage.setItem("userId", result.data._id);
        })
        .then(() => {
          window.location.href = "/Home";
        })
        .catch((err) => {
          console.log(err);
          // console.log(err.response.data.message);
          setMsg(err.response.data.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Styles>
      {/* Header */}
      <Header />
      {/* Main Wrapper */}
      <div className="main-wrapper login-page">
        {/* Breadcroumb */}
        <BreadcrumbBox title="Log In" />

        {/* Login Area */}
        <section className="login-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="login-box">
                  <div className="login-title text-center">
                    <h3>Log In</h3>
                  </div>
                  <form
                    id="form_login"
                    className="form"
                    onSubmit={onSubmitForm}
                  >
                    <p className="form-control">
                      <label htmlFor="login_user">UserName</label>
                      <input
                        type="text"
                        name="email"
                        placeholder="Enter your Email"
                        id="login_user"
                        onChange={onChangeInput}
                        value={inputs.email}
                      />
                    </p>
                    <p className="form-control">
                      <label htmlFor="login_password">Password</label>
                      <input
                        type="password"
                        name="password"
                        placeholder="*******"
                        id="login_password"
                        onChange={onChangeInput}
                        value={inputs.password}
                      />
                      <div style={{ color: "red" }}>{msg}</div>
                    </p>
                    <div className="save-forget-password d-flex justify-content-between">
                      <div className="forget-password">
                        <Link to={"/forget"}>Forget Password?</Link>
                      </div>
                    </div>
                    <button onClick={onSubmitForm}>Login</button>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
}

export default Login;
