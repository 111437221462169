import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "../common/styles/stickyMenu";
import axios from "axios";
import Logo from "../../image/logocps.png";

function StickyMenu() {
  const [menuData, setMenuData] = useState([]);
  const [subMenuData, setSubMenuData] = useState([]);
  const [fix, setFix] = useState("sticky-menu");
  const [isAbout, setIsAbout] = useState(false);
  const [aboutData, setAboutData] = useState([]);

  // For get menu
  const handleMenu = async () => {
    await axios
      .get("https://chandrodaymsmd.org:5500/chandrodaymsmd/menu/getMenu")
      .then((result) => {
        setMenuData(result.data);
        // console.log(result.data);
      })
      .catch((err) => console.log(err));
  };
  //handles about props
  const handleABout = (condition, data) => {
    setIsAbout(true);
    setAboutData(data);
  };

  // For get sub menu
  const handleSubMenu = async () => {
    await axios
      .get("https://chandrodaymsmd.org:5500/chandrodaymsmd/menu/sub/")
      .then((result) => {
        setSubMenuData(result.data);
        // console.log(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleMenu();
    handleSubMenu();

    window.addEventListener("scroll", () => {
      if (window.scrollY >= 120) {
        setFix("sticky-menu sticky");
      } else {
        setFix("sticky-menu");
      }
    });
  }, []);

  return (
    <Styles>
      {/* Sticky Menu */}
      <section className={fix}></section>
      <section
        className={fix}
        style={{ opacity: 1, backgroundColor: "transparent" }}
      >
        <Container style={{ maxWidth: "90%" }}>
          <Row>
            <Col md="4">
              <div className="logo">
                <Link to={"/"}>
                  <img src={Logo} alt="Logo" />
                  <div className="cps_name">
                    <div className="cps_n">Chandroday</div>
                    <span className="spanCPS">Public School</span>
                    <br />
                    <div className="affiliated">
                      <span className="affiliatedSpan">
                        Affiliation No. 3330391
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            </Col>
            <Col md="8">
              <div className="menu-box d-flex justify-content-end">
                <ul className="nav menu-nav">
                  {menuData.map((el, i) => {
                    return (
                      <li className="nav-item dropdown active" key={el._id}>
                        {el.type === "home" ? (
                          <Link
                            className="nav-link dropdown-toggle"
                            to={"/"}
                            data-toggle="dropdown"
                          >
                            {el.menuName}
                          </Link>
                        ) : el.type === "gallery" ? (
                          <Link
                            className="nav-link dropdown-toggle"
                            to={"/gallery"}
                            data-toggle="dropdown"
                          >
                            {el.menuName}
                          </Link>
                        ) : el.content ? (
                          <Link
                            to={`/aboutData/${el._id}`}
                            onClick={() => handleABout(true, el)}
                            type="button"
                            className="nav-link dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            {el.menuName}
                          </Link>
                        ) : (
                          <a
                            href="#"
                            type="button"
                            className="nav-link dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            {el.menuName}
                          </a>
                        )}
                        <ul className="dropdown list-unstyled">
                          {subMenuData?.map((elem) => {
                            if (elem.parent_id === el._id) {
                              return el.type === "faculties" ? (
                                <li className="nav-item" key={elem._id}>
                                  {elem._id === "64fc6e77e2b037cfaf0c854c" ? ( // Principal Submenu Id
                                    <Link
                                      className="nav-link"
                                      to={`/instructorDetails/64e854da6657751c63a4b52a`} // Principal Details
                                    >
                                      {elem.submenuName}
                                    </Link>
                                  ) : elem._id ===
                                    "64fc6e86e2b037cfaf0c855e" ? ( // Voice Principal Submenu Id
                                    <Link
                                      className="nav-link"
                                      to={`/instructorDetails/64eee41ce2b037cfaf0c63ea`} // Voice Principal Details
                                    >
                                      {elem.submenuName}
                                    </Link>
                                  ) : (
                                    <Link
                                      className="nav-link"
                                      to={`/instructor/${elem.parent_id}`}
                                    >
                                      {elem.submenuName}
                                    </Link>
                                  )}
                                </li>
                              ) : el.type === "academics" ? (
                                <li className="nav-item" key={elem._id}>
                                  <Link
                                    className="nav-link"
                                    to={`/academics/${elem._id}`}
                                  >
                                    {elem.submenuName}
                                  </Link>
                                </li>
                              ) : el.type === "gallery" ? (
                                <li className="nav-item" key={elem._id}>
                                  <Link
                                    className="nav-link"
                                    to={`/gallery/${elem.submenuName}`}
                                  >
                                    {elem.submenuName}
                                  </Link>
                                </li>
                              ) : (
                                <li className="nav-item" key={elem._id}>
                                  <Link
                                    className="nav-link"
                                    to={`/common/${elem._id}`}
                                  >
                                    {elem.submenuName}
                                  </Link>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </li>
                    );
                  })}
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href={
                        "https://studentandfee.com/?school_id=studentandfee100"
                      }
                      data-toggle="dropdown"
                    >
                      ERP LOGIN
                    </a>
                  </li>
                </ul>
                <div className="apply-btn">
                  <Link to={"/contact"}>
                    <i className="fa-regular fa-address-book fa-fade"></i>
                    Contact
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default StickyMenu;
