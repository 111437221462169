import React, { useEffect, useState } from 'react'
import axios from 'axios'
import './Styles/Modal.css'

const MenuUpdate = ({ id, closeModle }) => {

  const [values, setValues] = useState({
    id: id,
    menuName: ""
  })

  function handleClose() {
    closeModle()
  }

  // Get Menu Detailes

  const handleMenu = async () => {
    try {
      axios.get("https://chandrodaymsmd.org:5500/chandrodaymsmd/menu/getMenu/" + id)
        .then(res => {
          console.log(res.data)
          setValues({ ...values, menuName: res.data.menuName })
        })
        .catch(err => console.log(err))
    } catch (error) {
      console.log(error);
    }
  }


  // Update Menu Detailes

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put("https://chandrodaymsmd.org:5500/chandrodaymsmd/menu/updatemenu/" + id, values)
      .then(res => {
        console.log(res)
        alert("Data Updated Succesfully!");
        window.location.reload();
      })
      .catch(err => console.log(err))
  }


  useEffect(() => {
    handleMenu();
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "scroll";
    }
  }, [])


  return (
    <>
      <div className="modal-wrapper"></div>
      <div className='w-50 login-box mt-5 modal-container'>
        <div className='fa' onClick={handleClose} > &#xf00d;</div>
        <form className='container form' onSubmit={handleSubmit}>
          <h3 className='login-title'>Menu-Form</h3>
          <div className="mb-3">
            <label for="exampleInputHeading" className="form-label">Menu Name</label>
            <input type="text" className="form-control" id="exampleInputMenuName"
              value={values.menuName} onChange={e => setValues({ ...values, menuName: e.target.value })} required />
          </div>
          <button className="btn btn-success" >Update</button>
        </form>
      </div>
    </>
  );
};

export default MenuUpdate