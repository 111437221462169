import React, { useEffect, useState } from "react";
// import Datas from "../data/course/filter.json";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/courseFilter.js";
import axios from "axios";
import MoreDetails from "../pages/moreDetails/MoreDetails.js";
import { useParams } from 'react-router-dom';

function CourseFilter() {

  // It is submenu id
  const { menuId } = useParams();

  const [subMenuData, setSubMenuData] = useState([]);
  const [allContent, setAllContent] = useState([]);
  const [moreDetailsId, setMoreDetailsId] = useState(false);

  // in first reffresh page than it true and filter content according to current submenu id otherwise false
  const [filterContent, setFilterContent] = useState();

  // Get submenu data throw submenu id
  const handlUrlId = async () => {
    await axios
      .get(`https://chandrodaymsmd.org:5500/chandrodaymsmd/submenu/getSubmenu/${menuId}`)
      .then((result) => {

        // get Munu data: "result.data.parent_id" means menu id
        handleSubMenu(result.data.parent_id);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // filter submenu data throw the parent id
  const handleSubMenu = async (id) => {
    await axios
      .get(`https://chandrodaymsmd.org:5500/chandrodaymsmd/menu/sub/${id}`)
      .then((result) => {
        setSubMenuData(result.data);
        handleContent(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // For get Content
  const handleContent = async (subContent) => {
    await subContent.map((item) => {
      // console.log(item._id);
      axios
        .get(`https://chandrodaymsmd.org:5500/chandrodaymsmd/content/get/${item._id}`)
        .then((result) => {
          setAllContent((current) => [...current, result.data.data]);
          // console.log(result.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const onLoadContent = async (descText, key) => {
    // console.log("onLoadContent")
    await fetch(`https://chandrodaymsmd.org:5500/${descText}`)
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        const content = document.getElementById(key);
        content.innerHTML = data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setAllContent([]);
    handlUrlId();
    setFilterContent(menuId);
    setMoreDetailsId(false);
  }, [menuId]);

  useEffect(() => {
    const buttons = document.querySelector(".filter-btn-list").children;
    const items = document.querySelector(".filter-items").children;
    const card = document.getElementsByClassName("course-item");

    for (let i = 0; i < buttons.length; i++) {
      buttons[i].addEventListener("click", function () {

        // when reload page than it true otherwise everytime fales
        setFilterContent(false);

        // remove sub-heading active
        for (const button of buttons) {
          button.classList.remove("active");
        }

        // remove card active when click sub-heading
        for (const element of card) {
          element.style.border = "";
        }

        this.classList.add("active");
        const target = this.getAttribute("data-target");

        // hide and show card according to click
        for (const item of items) {
          item.style.display = "none";

          if (item.getAttribute("data-id") === target) {
            item.style.display = "block";
          }

          if (target === "*") {
            item.style.display = "block";
          }
        }
      });

      // when reload page then it works
      if (filterContent) {
        const target = buttons[i].getAttribute("data-target");
        buttons[i].classList.remove("active");
        if (target === menuId) {
          buttons[i].classList.add("active");
        }
      }
    }

    // for card active
    for (let i = 0; i < card.length; i++) {
      card[i].addEventListener("click", function () {
        for (const element of card) {
          element.style.border = "";
        }
        this.style.border = "2px solid #11B67A";
      });
    }
  });

  return (
    <Styles>
      {/* Course Area */}
      <section className="course-filter">
        <Container style={{ maxWidth: "90%" }}>
          <Row>
            <Col md="12">
              <div className="filter-btns text-center">
                <ul className="filter-btn-list list-unstyled list inline">
                  <li data-target="*" className="list-inline-item">
                    All
                  </li>
                  {subMenuData.map((item, i) => {
                    return (
                      <li
                        key={i}
                        data-target={item._id}
                        className="list-inline-item"
                        onClick={() => { setMoreDetailsId(false) }}
                      >
                        {item.submenuName}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <Row className="filter-items d-flex justify-content-evenly">
                {allContent.map((data) =>
                  data.map((item, index) => {
                    let extension = item.contentImage.split('.')[1].toLowerCase();
                    onLoadContent(item.contentDiscription, item._id);
                    return (
                      filterContent ? item.subMenu_id == menuId ?
                        <Col
                          lg="3"
                          md="4"
                          key={index}
                          data-id={item.subMenu_id}
                          onClick={() => {
                            setMoreDetailsId(item._id)
                          }}
                        >
                          <div className="course-item">
                            <div className="course-image">
                              {
                                extension == 'jpeg' || extension == 'jpg' || extension == 'png' ?
                                  <img src={`https://chandrodaymsmd.org:5500/${item.contentImage}`} alt="Image Not Found" />
                                  :
                                  <iframe
                                    src={`https://chandrodaymsmd.org:5500/${item.contentImage}#toolbar=0&navpanes=0&scrollbar=0`}
                                    className="iframeSettings"
                                    frameBorder="0"
                                    scrolling="no"
                                  ></iframe>
                              }
                            </div>
                            <div className="course-content">
                              <h6 className="heading">{item.contentHeading}</h6>
                              <hr />
                              <div className="course-face d-flex justify-content-between">
                                <p className="desc" id={item._id}></p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        : <></> :
                        <Col
                          lg="3"
                          md="4"
                          key={index}
                          data-id={item.subMenu_id}
                          onClick={() => {
                            setMoreDetailsId(item._id)
                          }}
                        >
                          <div className="course-item">
                            <div className="course-image">
                              {
                                extension == 'jpeg' || extension == 'jpg' || extension == 'png' ?
                                  <img src={`https://chandrodaymsmd.org:5500/${item.contentImage}`} alt="Image Not Found" />
                                  :
                                  <iframe
                                    src={`https://chandrodaymsmd.org:5500/${item.contentImage}#toolbar=0&navpanes=0&scrollbar=0`}
                                    className="iframeSettings"
                                    frameBorder="0"
                                    scrolling="no"
                                  ></iframe>
                              }
                            </div>
                            <div className="course-content">
                              <h6 className="heading">{item.contentHeading}</h6>
                              <hr />
                              <div className="course-face d-flex justify-content-between">
                                <p className="desc" id={item._id}></p>
                              </div>
                            </div>
                          </div>
                        </Col>
                    )
                  })
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <div id={moreDetailsId}>
        {moreDetailsId && <MoreDetails moreDetailsId={moreDetailsId} />}
      </div>
    </Styles>
  );
}

export default CourseFilter;
