import React, { useEffect, useState } from "react";
import NavBar from "../NavBar";
import SideBar from "../SideBar";
import axios from "axios";
import { useHistory } from "react-router-dom";

const Slider = () => {
  const navigate = useHistory();

  const [inputData, setInputData] = useState({
    file: "",
  });
  const [imageDimention, setImageDimention] = useState("no");

  const handleDimention = () => {
    // console.log(inputData.file);
    try {
      const imgUrl = window.URL.createObjectURL(inputData.file);
      const img = new Image();
      img.src = imgUrl;
      // console.log(imgUrl);
      let width;
      let height;

      img.onload = () => {
        width = img.naturalWidth;
        height = img.naturalHeight;
        return width >= 1920 && height >= 814 && width <= 1950 && height <= 1012
          ? setImageDimention(true)
          : setImageDimention(false);
      };
    } catch (error) {
      console.log(error);
    }
  };

  const handleSlider = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", inputData.file);

    try {
      await axios
        .post(
          `https://chandrodaymsmd.org:5500/chandrodaymsmd/slider/insert`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
        })
        .then(() =>{
          alert("Data Added Successfully");
         navigate.push("/Home");
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <NavBar />

      <div className="w-50 login-box mt-5">
        <div className="container">
          <form className="form" type="post" encType="multipart/form-data">
            <h3 className="login-title">ADD Slider</h3>
            <div className="mb-3">
              <div className="row">
                <div className="col-md-10">
                  <label htmlFor="inputImage" className="form-label">
                    Select Image
                  </label>
                  <input
                    name="file"
                    type="file"
                    className="form-control"
                    id="inputImage"
                    onChange={(e) => setInputData({ file: e.target.files[0] })}
                    required
                  />
                  {imageDimention === true ? (
                    <span className="fw-bold imgDimention">Image Accepted</span>
                  ) : imageDimention === false ? (
                    <span className="text-danger imgDimention">
                      Image dimension should be min 1920 X 814 and max 1950 X
                      1012
                    </span>
                  ) : (
                    <span className="fst-italic">
                      If you want a better image quality than check dimention
                    </span>
                  )}
                </div>
                {inputData.file ? (
                  <div className="col-md-2">
                    <label htmlFor="#">Dimension</label>
                    <button
                      type="button"
                      className="btn btn-warning btn-sm"
                      onClick={handleDimention}
                    >
                      check
                    </button>
                  </div>
                ) : (
                  <div className="col-md-2">
                    <label htmlFor="#">Dimension</label>
                    <button
                      disabled
                      className="btn btn-danger btn-sm disabled"
                      onClick={handleDimention}
                    >
                      check
                    </button>
                  </div>
                )}
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSlider}
            >
              Add
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Slider;
