import React from "react";
import "./styles/SideBar.css";
import { NavLink } from "react-router-dom";

function SideBar() {
  return (
    <div className="aside">
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <NavLink to="/" className="brand-link Admin">
          <span className="brand-text font-weight-light">Admin Panel</span>
        </NavLink>
        <div className="sidebar">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                src="dist/img/logo.jpg"
                className="img-circle elevation-2"
                alt=""
              />
            </div>
            <div className="info">
              <NavLink to="/" className="d-block Title">
                Chandroday Public School
              </NavLink>
            </div>
          </div>
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item menu-open">
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/home" className="nav-link ">
                      <p>Home</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/MenuForm" className="nav-link ">
                      <p>Menu</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/SubMenuForm" className="nav-link">
                      <p>Sub-Menu </p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/ContentForm" className="nav-link">
                      <p>Content</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/Faculty" className="nav-link">
                      <p>Faculty</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/Slider" className="nav-link">
                      <p>Add Slider</p>
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
}

export default SideBar;
