import React, { Component, useEffect, useState } from "react";
import Datas from "../../data/gallery/gallery-page.json";
import { Container, Row, Col } from "react-bootstrap";
import ModalImage from "react-modal-image";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Styles } from "./styles/gallery.js";
import Header from "../../components/Header";
import axios from "axios";
import { useParams } from 'react-router-dom';
import "./styles/gallery.css";

function Gallery() {

    const { subMenuName } = useParams();
    const [gallery, setGallery] = useState([]);

    async function getGallery() {
        axios
            .get(`https://chandrodaymsmd.org:5500/chandrodaymsmd/gallery/get`)
            .then((result) => {
                setGallery(result.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const filterSelection = (subMenuName) => {
        const tabs = document.querySelector(".allTabs").children;
        for (let i = 0; i < tabs.length; i++) {
            const element = tabs[i];
            const clssname = element.className.split(" ");
            console.log(clssname);
            if (subMenuName !== 'all') {
                element.classList.add("hidden");
                clssname.forEach(cname => {
                    if (cname === subMenuName.split(" ")[0]) {
                        element.classList.remove("hidden");
                    }

                });
            } else {
                element.classList.remove("hidden");
            }
        }
    }

    useEffect(() => {
        if (subMenuName) {
            filterSelection(subMenuName);
        } else {
            filterSelection("all");
        }
    }, [subMenuName]);

    useEffect(() => {
        getGallery();
    }, []);

    // console.log(gallery)

    return (
        <Styles>

            {/* Header */}
            <Header />

            {/* Main Wrapper */}
            <div className="main-wrapper gallery-page">

                {/* Breadcroumb */}
                <BreadcrumbBox title="Gallery" />

                {/* Gallery Area */}
                <section className="gallery-page-area">
                    <Container>
                        <Row className="allTabs">
                            {gallery.map((data, i) => (
                                <Col lg="4" sm="6" key={i} className={data.category}>
                                    {/* {console.log(data)} */}
                                    <div className="gallery-box">
                                        <ModalImage
                                            small={`https://chandrodaymsmd.org:5500/${data.galleryImage}`}
                                            large={`https://chandrodaymsmd.org:5500/${data.galleryImage}`}
                                            alt={data.galleryDesc}
                                        // style={{ width: '100%', height: '22rem' }}
                                        />
                                        <div className="gallery-description">{data.galleryDesc}</div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </section>
            </div>
        </Styles>
    );
}

export default Gallery;
