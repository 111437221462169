import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Link } from "react-router-dom";
import { Button, Nav, Navbar } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { authAction } from "../Redux/Store";

function NavBar() {
  const dispatch = useDispatch();

  function logOut() {
    dispatch(authAction.logout());
    window.location.href = "/";
  }

  return (
    <div>
      <Navbar expand="lg" style={{ backgroundColor: "#182B49" }}>
        <Container>
          <Link to={"/Home"}>
            {" "}
            <Navbar.Brand className="text-light me-5 fs-3">
              Chandroday Public School
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <Link to={"/Home"} className="text-light fs-5">
                  Home
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to={"/MenuForm"} className="text-light fs-5">
                  Menu
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to={"/SubMenuForm"} className="text-light fs-5">
                  Sub-Menu
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to={"/ContentForm"} className="text-light fs-5">
                  Content
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to={"/Faculty"} className="text-light fs-5">
                  Faculty
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to={"/Message"} className="text-light fs-5">
                  Message
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to={"/Slider"} className="text-light fs-5">
                  Slider
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to={"/GalleryForm"} className="text-light fs-5">
                  Gallery
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to={"/Notice"} className="text-light fs-5">
                  Notice
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to={"/Thought"} className="text-light fs-5">
                  Thought
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to={"/SuccessStory"} className="text-light fs-5">
                  Success_Story
                </Link>
              </Nav.Link>

              <div className="ms-5">
                <Button onClick={logOut}>
                  <i className="las la-user-edit" />
                  Logout
                </Button>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavBar;
