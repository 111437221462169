import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { Styles } from "./styles/contact.js";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class GoogleMap extends Component {
    static defaultProps = {
        center: {
            lat: 21.103276948142344,
            lng: 82.09837435131725,
        },
        zoom: 11,
    };

    render() {
        return (
            <Styles>
                {/* Google Map */}
                <div className="google-map-area">
                    {/* <GoogleMapReact
                        bootstrapURLKeys={{ key: "" }}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                    >
                        <AnyReactComponent
                            lat={21.103276948142344}
                            lng={82.09837435131725}
                            text="My Marker"
                        />
                    </GoogleMapReact> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3722.2600047503224!2d82.09463507566099!3d21.102197885251396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28a588469a6f77%3A0x2754fa15d25f5be2!2sChandroday%20Public%20School%2CMahasamund!5e0!3m2!1sen!2sin!4v1691644969399!5m2!1sen!2sin" allowFullScreen width="100%" height="400px" />
                </div>
            </Styles>
        );
    }
}

export default GoogleMap;
