import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Styles } from "./styles/account.js";
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Header from "../../components/Header";

function Forget() {
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const Navigate = useHistory();

  const onSubmitForm = async (e) => {
    e.preventDefault();

    await axios.post(`https://chandrodaymsmd.org:5500/chandrodaymsmd/user/forget-password`, {
      email,
    }).then((res) => {
      console.log("forget")
      setMsg("Successfully Sent")
    }).then(() => {
      Navigate.push("/login");
    }).catch((err) => {
      console.log(err);
      setMsg("Email Invalid");
    })
  }

  return (
    <Styles>
{/* Header */}
<Header />
      {/* Main Wrapper */}
      <div className="main-wrapper login-page">
        {/* Breadcroumb */}
        <BreadcrumbBox title="Forget" />

        {/* Login Area */}
        <section className="login-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="login-box">
                  <div className="login-title text-center">
                    <h3>Forget Id</h3>
                  </div>
                  <form id="form_login" className="form">
                    <p className="form-control">
                      <label htmlFor="login_user">E-mail</label>
                      <input
                        type="email"
                        placeholder="E-mail"
                        id="login_user"
                        onChange={(ev) => setEmail(ev.target.value)}
                        value={email.email}
                      />
                      <div style={{ color: "red" }}>{msg}</div>
                    </p>
                    <button onClick={onSubmitForm}>Send</button>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

      </div>
    </Styles>
  );
}

export default Forget;
