import React, { useState, useEffect } from "react";
import NavBar from "../NavBar";
import "./Styles/Form.css";
import axios from "axios";
import { useHistory } from "react-router-dom";

function Gallery_Form() {
    const [GallerySubmenu, setGallerySubmenu] = useState([]);
    const [toggle, setToggle] = useState(true);
    // const [isCategory, setIsCategory] = useState(false);

    const [inputs, setInputs] = useState({
        file: "",
        description: "",
        selectCategory: "",
        newCategory: "",
    });

    console.log(inputs.file);

    const navigate = useHistory();

    const handleGalleryData = async () => {
        try {
            await axios.get("https://chandrodaymsmd.org:5500/chandrodaymsmd/menu/sub/64ccb15f62dd6c3a50802f56")
                .then(response => setGallerySubmenu(response.data))
                .catch(err => console.log(err))
        } catch (error) {
            console.log(error);
        }
    };

    function handleChange(e) {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value,
        });
    }

    // Submite the data of the Gallery
    const onSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", inputs.file);
        formData.append("galleryDesc", inputs.description);


        if (inputs.newCategory) {
            formData.append("category", inputs.newCategory);
            await axios.post("https://chandrodaymsmd.org:5500/chandrodaymsmd/submenu/addSubmenu", {
                submenuName: inputs.newCategory,
                parent_id: "64ccb15f62dd6c3a50802f56",
            });

            await axios.post("https://chandrodaymsmd.org:5500/chandrodaymsmd/gallery/add", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            alert("Data Added Successfully");
            navigate.push("/Home");
        } else {
            formData.append("category", inputs.selectCategory);
            await axios.post("https://chandrodaymsmd.org:5500/chandrodaymsmd/gallery/add", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            alert("Data Added Successfully");
            navigate.push("/Home");
        }

    };

    useEffect(() => {
        setInputs({ ...inputs, selectCategory: "" });
        setInputs({ ...inputs, newCategory: "" });
    }, [toggle]);

    useEffect(() => {
        handleGalleryData();
    }, []);

    return (
        <div>
            <NavBar />
            <section className="container">
                <div className="w-50 login-box mt-5">
                    <form className="container form" onSubmit={onSubmit}>
                        <h3 className="login-title">Gallery-Form</h3>

                        <div className="mb-3">
                            <label htmlFor="exampleInputImage" className="form-label">
                                Image
                            </label>
                            <div>
                                <input type="file" accept="image/*" onChange={(e) => { setInputs({ file: e.target.files[0] }); }} required />
                            </div>
                        </div>
                        {toggle ?
                            <div className="mb-3">
                                <label for="exampleSelectCategory" class="form-label">
                                    Select Category
                                </label>
                                <select
                                    id="exampleSelectCategory"
                                    name="selectCategory"
                                    value={inputs.selectCategory}
                                    className="form-control"
                                    onChange={handleChange}
                                >
                                    <option value="">Select Name</option>
                                    {GallerySubmenu.map((data, i) => {
                                        return (
                                            <option value={data.submenuName} key={i}>
                                                {data.submenuName}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                            :
                            <div className="mb-3">
                                <label htmlFor="exampleInputCategory" className="form-label">
                                    Catagory
                                </label>
                                <div>
                                    <input id="exampleInputCategory" type="text" name="newCategory" value={inputs.newCategory} className="form-control" onChange={handleChange} placeholder="Enter Category" />
                                </div>
                            </div>
                        }

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" onChange={(e) => setToggle(!toggle)} />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                If you want to add new category then check it
                            </label>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="exampleInputDescription" className="form-label">
                                Description
                            </label>
                            <textarea type="text" name="description" className="form-control" id="exampleInputDescription" onChange={handleChange} required />
                        </div>

                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </form>
                </div>
            </section>
        </div>
    )
}

export default Gallery_Form