import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`

    @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,500&display=swap');

    .noticeList{
        max-height: 20rem;
        overflow: auto;
        text-transform: uppercase;
        font-family: 'Roboto', sans-serif;
    }

    .noticeList ul{
        list-style-position: inside;
        list-style: none;
    }

    .card-header{
        background-color: ${colors.green};
        // background-color: #A0D995;
    }
`;
