import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/aboutData.js";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import Header from "./Header";

const isOpenStyle = {
  WebkitLineClamp: 12,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  display: '-webkit-box'
}

function AboutData() {
  const { Id } = useParams();
  const [menuData, setMenuData] = useState();
  const [isOpen, setIsOpen] = useState(false);

  // For get menu data
  const handleMenuData = async () => {
    await axios
      .get(`https://chandrodaymsmd.org:5500/chandrodaymsmd/menu/getMenu/${Id}`)
      .then((result) => {
        setMenuData(result.data);
        onLoadContent(result.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // fetch text data from the server
  const onLoadContent = async (descText) => {
    await fetch(`https://chandrodaymsmd.org:5500/${descText}`)
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        const content = document.getElementById("abParagraph");
        content.innerHTML = data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleMenuData();
  }, [Id]);

  return (
    <Styles>
      {/* Header */}
      <Header />

      {/* About Us */}
      <section className="about-us">
        <Container style={{ maxWidth: "90%" }}>
          <Row>
            <Col md="6">
              <div className="about-image">
                <img
                  src={`https://chandrodaymsmd.org:5500/${menuData?.contentImage}`}
                  className="main-img"
                  alt="Image"
                />
              </div>
            </Col>
            <Col md="6">
              <div className="about-content">
                <h4 className="about-title">{menuData?.contentHeading}</h4>
                <p className="about-para" id="abParagraph" style={isOpen ? null : isOpenStyle}></p>
                <a className="readmore-btn" onClick={() => setIsOpen(!isOpen)}>
                  {isOpen ? "Read Less" : "Read More"}
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default AboutData;
