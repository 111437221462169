import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import GoogleMap from "./GoogleMap";
import { Styles } from "./styles/contact.js";
import axios from "axios";
import Header from "../../components/Header";

function Contact() {
  const [msg, setMsg] = useState("");

  useEffect(() => {
    const form = document.getElementById("form_contact");
    const name = document.getElementById("contact_name");
    const email = document.getElementById("contact_email");
    const subject = document.getElementById("contact_subject");
    const message = document.getElementById("contact_message");

    form.addEventListener("submit", formSubmit);

    async function formSubmit(e) {
      e.preventDefault();

      const nameValue = name.value.trim();
      const emailValue = email.value.trim();
      const subjectValue = subject.value.trim();
      const messageValue = message.value.trim();

      if (nameValue === "") {
        setError(name, "Name can't be blank");
      } else {
        setSuccess(name);
        if (emailValue === "") {
          setError(email, "Email can't be blank");
        } else if (!isEmail(emailValue)) {
          setError(email, "Not a valid email");
        } else {
          setSuccess(email);
          if (subjectValue === "") {
            setError(subject, "Subject can't be blank");
          } else {
            setSuccess(subject);
            if (messageValue === "") {
              setError(message, "Message can't be blank");
            } else {
              setSuccess(message);
              await axios
                .post(
                  "https://chandrodaymsmd.org:5500/chandrodaymsmd/contactUs/touch",
                  {
                    fullName: nameValue,
                    email: emailValue,
                    subject: subjectValue,
                    message: messageValue,
                  }
                )
                .then((res) => {
                  setMsg("Successfully sent message");
                  name.value = "";
                  email.value = "";
                  subject.value = "";
                  message.value = "";
                })
                .catch((err) => {
                  console.log(err + "Contact");
                });
            }
          }
        }
      }
    }

    function setError(input, message) {
      const formControl = input.parentElement;
      const errorMsg = formControl.querySelector(".contact_input-msg");
      formControl.className = "form-control text-left error";
      errorMsg.innerText = message;
    }

    function setSuccess(input) {
      const formControl = input.parentElement;
      formControl.className = "form-control success";
    }

    function isEmail(email) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
    }
  });

  return (
    <Styles>
      {/* Header  */}
      <Header />

      {/* Main Wrapper */}
      <div className="main-wrapper contact-page">
        {/* Breadcroumb */}
        <BreadcrumbBox title="Contact Us" />

        {/* Contact Area */}
        <section className="contact-area">
          <Container>
            <Row>
              <Col md="4">
                <div className="contact-box-title">
                  <h4>Contact Info</h4>
                </div>
                <div className="contact-icon-box d-flex">
                  <div className="icon">
                    <i className="las la-map-marker"></i>
                  </div>
                  <div className="box-content">
                    <h5>Our Location</h5>
                    <p>Bagbahra Rd, Mahasamund, Chhattisgarh 493445</p>
                  </div>
                </div>
                <div className="contact-icon-box d-flex">
                  <div className="icon">
                    <i className="las la-envelope-open"></i>
                  </div>
                  <div className="box-content">
                    <h5>Email Address</h5>
                    <p>
                      info@chandrodaymsmd.org
                    </p>
                  </div>
                </div>
                <div className="contact-icon-box d-flex">
                  <div className="icon">
                    <i className="las la-phone"></i>
                  </div>
                  <div className="box-content">
                    <h5>Phone Number</h5>
                    <p>
                      Mobile- 72249-36878
                      <br />
                      Landline- 07723-299349
                    </p>
                  </div>
                </div>
                <div className="contact-social">
                  <ul className="social list-unstyled list-inline">
                    <li className="list-inline-item">
                      <a href={"https://www.facebook.com/people/Chandroday-Public-School/100063768079557/"} target="_blank">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href={"https://twitter.com/ChandrodayPubl1"}
                        target="_blank"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href={
                          "https://www.linkedin.com/in/chandroday-public-school-mahasamund-8357b01aa/"
                        }
                        target="_blank"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={"https://www.youtube.com/@chandrodaypublicschoolmaha2301"} target="_blank">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href={"https://www.instagram.com/cpsmsmd722/"}
                        target="_blank"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="8">
                <div className="contact-form">
                  <div className="form-title">
                    <h4>Get In Touch</h4>
                    <div style={{ color: "green" }}>{msg}</div>
                  </div>
                  <div className="form-box">
                    <form id="form_contact" className="form">
                      <Row>
                        <Col md="6">
                          <p className="form-control">
                            <input
                              type="text"
                              placeholder="Full Name"
                              id="contact_name"
                            />
                            <span className="contact_input-msg"></span>
                          </p>
                        </Col>
                        <Col md="6">
                          <p className="form-control">
                            <input
                              type="email"
                              placeholder="Email Address"
                              id="contact_email"
                            />
                            <span className="contact_input-msg"></span>
                          </p>
                        </Col>
                        <Col md="12">
                          <p className="form-control">
                            <input
                              type="text"
                              placeholder="Subject"
                              id="contact_subject"
                            />
                            <span className="contact_input-msg"></span>
                          </p>
                        </Col>
                        <Col md="12">
                          <p className="form-control">
                            <textarea
                              name="message"
                              id="contact_message"
                              placeholder="Enter Message"
                            ></textarea>
                            <span className="contact_input-msg"></span>
                          </p>
                        </Col>
                        <Col md="12">
                          <button>Send Message</button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          {/* Google Map */}
          <GoogleMap />
        </section>
      </div>
    </Styles>
  );
}

export default Contact;
