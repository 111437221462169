import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Styles } from "./styles/notice_circular.js";
import axios from "axios";
import { format, parseISO, addDays } from "date-fns";
import { Link } from "react-router-dom";

function Notice_Circular() {
  const [noticeDetails, setNoticeDetails] = useState([]);

  const handleNorices = async () => {
    try {
      await axios
        .get("https://chandrodaymsmd.org:5500/chandrodaymsmd/notice/get")
        .then((result) => setNoticeDetails(result.data.data))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleNorices();
  }, []);

  return (
    <section className="container mt-5  pt-5">
      <Styles>
        <div className="shadow">
          <Card>
            <Card.Header>
              <div className="cardHeader">
                <h4 className="text-center">
                  <strong>Notice / Circular</strong>
                </h4>
              </div>
            </Card.Header>
            <Card.Body>
              {/* <Card.Title>Special title treatment</Card.Title> */}
              <Card.Text>
                <div className="noticeList container pb-3">
                  <ul className="mx-3">
                    {noticeDetails.map((data, i) => (
                      <li className="p-3 mb-2 border-bottom" key={i}>
                        {data.noticePdf ? (
                          <a
                            href={`https://chandrodaymsmd.org:5500/${data.noticePdf}`}
                          >
                            <i class="fa-sharp fa-solid fa-arrow-right-long fa-beat"></i>{" "}
                            {data.noticeDesc}
                          </a>
                        ) : (
                          <Link to="#">
                            <i class="fa-sharp fa-solid fa-arrow-right-long fa-beat"></i>{" "}
                            {data.noticeDesc}
                          </Link>
                        )}
                        {format(new Date(), "yyyy-MM-dd") <=
                        format(
                          addDays(parseISO(data.noticeDate), 7),
                          "yyyy-MM-dd"
                        ) ? (
                          <img src="/assets/cps_image/new.gif" alt="error" />
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </Styles>
    </section>
  );
}

export default Notice_Circular;
