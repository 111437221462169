import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Styles/Modal.css";
const SubMenuUpdate = ({ id, pid, closeSubMenu }) => {
  const [menuDetails, setMenuDetails] = useState([]);
  const [values, setValues] = useState({
    parent_id: pid,
    submenuName: "",
  });

  function handleClose() {
    closeSubMenu();
  }

  // Get Menu Detailes
  const handleMenuDetails = async () => {
    try {
      axios
        .get("https://chandrodaymsmd.org:5500/chandrodaymsmd/menu/getMenu")
        .then((result) => setMenuDetails(result.data))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  // Get Sub-Menu Detailes
  const handleSubMenuDetails = async () => {
    try {
      axios
        .get(`https://chandrodaymsmd.org:5500/chandrodaymsmd/submenu/getSubMenu/${id}`)
        .then((result) => setValues({ ...values, submenuName: result.data.submenuName }))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  // Update Menu Detailes
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(
        "https://chandrodaymsmd.org:5500/chandrodaymsmd/submenu/updateSubmenu/" +
        id,
        values
      )
      .then((res) => {
        alert("Data Updated Succefully!");
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    handleMenuDetails();
    handleSubMenuDetails();
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);
  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="w-50 login-box mt-5 modal-container">
        <div className="fa" onClick={handleClose}>
          {" "}
          &#xf00d;
        </div>
        <form
          encType="multipart/form-data"
          className="m-3"
          onSubmit={handleSubmit}
        >
          <div className="form-group mb-3">
            <h3 className="login-title">Sub-Menu-Form</h3>
            <div className="mb-3">
              <label className="form-label">Select Menu</label>
              {/* selecting parent id for perticular submenu */}
              <select
                name="parantNames"
                className="form-control"
                onChange={(e) =>
                  setValues({ ...values, parent_id: e.target.value })
                }
                required
              >
                <option value={values.menuName}>Select Name</option>
                {menuDetails.map((data, i) => (
                  <option value={data._id} key={i} selected={data._id === pid}>
                    {data.menuName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mb-3">
            <label for="sub_heading" className="form-label">
              Sub Heading
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleInputSubmenuName"
              value={values.submenuName}
              onChange={(e) =>
                setValues({ ...values, submenuName: e.target.value })
              }
            />
          </div>
          <button type="submit" className="btn btn-success">
            Update
          </button>
        </form>
      </div>
    </>
  );
};
export default SubMenuUpdate;
