import React, { useEffect, useState } from 'react'
import axios from 'axios'
import './Styles/Modal.css'
import { useHistory } from "react-router-dom"

const FacultyUpdate = ({ id, closeFacultyModal }) => {

    const navigate = useHistory()

    const [values, setValues] = useState({
        id: id,
        Name: "",
        file: "",
        Designation: "",
        Description: "",
        Qualification: "",
        Email: "",
        Image: "",
    })

    // Get Faculty Detailes
    useEffect(() => {
        axios.get("https://chandrodaymsmd.org:5500/chandrodaymsmd/faculty/get/" + id)
            .then(res => {
                setValues({
                    ...values, Name: res.data.data.Name, Image: res.data.data.Image, Designation: res.data.data.Designation,
                    Description: res.data.data.Description, Qualification: res.data.data.Qualification, Email: res.data.data.Contact[0].Email
                })
            })
            .catch(err => console.log(err))
    }, [])

    // Update Faculty Detailes
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("Name", values.Name);
        formData.append("Designation", values.Designation);
        formData.append("Description", values.Description);
        formData.append("Qualification", values.Qualification);
        formData.append("file", values.file);
        formData.append("Email", values.Email);

        try {
            await axios.patch(`https://chandrodaymsmd.org:5500/chandrodaymsmd/faculty/update/${values.id}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then(alert("Data Added Successfully"),
                window.location.reload());
        } catch (error) {
            console.log(error);
        }
    };

    function handleClose() {
        closeFacultyModal()
    }

    useEffect(() => {
        document.body.style.overflowY = "hidden";
        return () => {
            document.body.style.overflowY = "scroll";
        }
    }, [])


    return (
        <>
            <div className="modal-wrapper"></div>
            <div className='w-50 login-box  mt-5 modal-container'>
                <div className='fa' onClick={handleClose}> &#xf00d;</div>
                <form className='container form' onSubmit={handleSubmit} enctype="multipart/form-data">
                    <h3 className='login-title'>Faculty-Form</h3>
                    <div className="mb-3">
                        <label for="exampleInputName" className="form-label">Name</label>
                        <input type="text" className="form-control" id="exampleInputName"
                            value={values.Name} onChange={e => setValues({ ...values, Name: e.target.value })} required />
                    </div>
                    <div className="mb-3">
                        <label for="exampleInputImage" className="form-label">Image</label>
                        <div>
                            <input type='file' name='file' accept='image/*'
                                onChange={e => setValues({ ...values, file: e.target.files[0] })} />
                            <img width="65" src={`https://chandrodaymsmd.org:5500/${values.Image}`} />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label for="exampleInputDesignation" className="form-label">Designation</label>
                        <input type="text" className="form-control" id="exampleInputDesignation"
                            value={values.Designation} onChange={e => setValues({ ...values, Designation: e.target.value })} required />
                    </div>
                    <div className="mb-3">
                        <label for="exampleInputDescription" className="form-label">Description</label>
                        <textarea type="text" className="form-control" id="exampleInputPassword1"
                            value={values.Description} onChange={e => setValues({ ...values, Description: e.target.value })} required />
                    </div>
                    <div className="mb-3">
                        <label for="exampleInputQualification" className="form-label">Qualification</label>
                        <input type="text" className="form-control" id="exampleInputDescription"
                            value={values.Qualification} onChange={e => setValues({ ...values, Qualification: e.target.value })} required />
                    </div>
                    <div className="mb-3">
                        <label for="exampleInputQualification" className="form-label">E-mail</label>
                        <input type="email" className="form-control" id="exampleInputEmail"
                            value={values.Email} onChange={e => setValues({ ...values, Email: e.target.value })} required />
                    </div>
                    <div className="mb-3">
                        <div>
                            <button type="submit" className="btn btn-success" >Update</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default FacultyUpdate