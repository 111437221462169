import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Styles } from "./styles/mobileMenu.js";
import axios from "axios";
import Logo from "../../image/logocps.png";
import { useDispatch, useSelector } from "react-redux";
import { authAction } from "../../Redux/Store.js";

function MobileMenu() {
  const [menuData, setMenuData] = useState([]);
  // console.log(menuData);
  const [subMenuData, setSubMenuData] = useState([]);
  // console.log(subMenuData);

  const dispatch = useDispatch();
  const { curUser, isLoggedIn } = useSelector((state) => state.user);
  const userName = curUser?.UserName;

  function logOut() {
    dispatch(authAction.logout());
    window.location.href = "/";
  }

  // For get menu
  const handleMenu = async () => {
    await axios
      .get("https://chandrodaymsmd.org:5500/chandrodaymsmd/menu/getMenu")
      .then((result) => {
        setMenuData(result.data);
        // console.log(result.data);
      })
      .catch((err) => console.log(err));
  };

  // For get sub menu
  const handleSubMenu = async () => {
    await axios
      .get("https://chandrodaymsmd.org:5500/chandrodaymsmd/menu/sub/")
      .then((result) => {
        setSubMenuData(result.data);
        // console.log(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // Mobile Menu
    const hmBtn = document.getElementById("mb-sidebar-btn");

    if (hmBtn) {
      const mdSidebarOverlay = document.getElementById("mb-sidebar-overlay");
      const mdSidebarBody = document.getElementById("mb-sidebar-body");
      const mdSidebarExit = document.getElementById("close-mb-sidebar");

      hmBtn.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.toggle("visible");
        mdSidebarBody.classList.toggle("opened");
      });

      mdSidebarOverlay.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.remove("visible");
        mdSidebarBody.classList.remove("opened");
      });

      mdSidebarExit.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.remove("visible");
        mdSidebarBody.classList.remove("opened");
      });
    }

    // Menu Accordion -----------------
    const menuButton = document.querySelectorAll(".mb-menu-button");
    // console.log(menuButton);
    menuButton.forEach((button) => {
      button.addEventListener("click", () => {
        button.classList.toggle("active");
        const content = button.nextElementSibling;

        if (button.classList.contains("active")) {
          content.className = "mb-menu-content show";
          content.style.maxHeight = content.scrollHeight + "px";
        } else {
          content.className = "mb-menu-content";
          content.style.maxHeight = "0";
        }
      });
    });
    handleMenu();
    handleSubMenu();
  }, []);

  return (
    <Styles>
      {/* Mobile Menu */}
      <section className="mobile-menu-area">
        <Container style={{ maxWidth: "95%" }}>
          <Row>
            <Col md="0" sm="12">
              <div className="mb-topbar d-flex justify-content-between">
                <div className="topbar-item">
                  <p>
                    <i className="fa-solid fa-location-dot"></i>
                    Mahasamund
                  </p>
                </div>

                <div className="topbar-item">
                  {!isLoggedIn ? (
                    <ul className="list-unstyled list-inline">
                      <li className="list-inline-item">
                        <Link to={"/login"}>
                          <i className="fa-solid fa-user"></i>Log In
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    <ul className="list-unstyled list-inline">
                      <Link to="/home" style={{ color: "#ffffff" }}>
                        <li className="list-inline-item">
                          <i
                            className="fa-regular fa-user"
                            style={{ color: "#11b67a" }}
                          ></i>
                          {"  " + userName}
                        </li>
                      </Link>
                      <li className="list-inline-item">
                        <Button onClick={logOut}>
                          <i className="las la-user-edit" />
                          Logout
                        </Button>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
              <div className="mb-logo-area d-flex justify-content-between">
                <div className="mb-logo-box d-flex">
                  <div className="mb-logo">
                    <Link to={"/"}>
                      <img src={Logo} alt="Logo Image" />
                    </Link>
                  </div>
                  <div className="cps_name">
                    Chandroday Public School
                    <br />
                    <div className="affiliated">
                      <span>Affiliated To C.B.S.E.</span>
                      <br />
                      <span style={{ marginTop: "-12px" }}>
                        Affiliation No. 3330391
                      </span>
                    </div>
                  </div>
                  <div className="hm-button">
                    <Link to={"/"} id="mb-sidebar-btn">
                      <i className="fa-solid fa-bars"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Mobile Menu Sidebar */}
      <section className="mb-sidebar" id="mb-sidebar-body">
        <div className="mb-sidebar-heading d-flex justify-content-between">
          <div>
            <Link to="/">
              <h5>Chandroday Public School</h5>
            </Link>
          </div>
          <div>
            <a href={"/"} id="close-mb-sidebar">
              <i className="fa-solid fa-xmark"></i>
            </a>
          </div>
        </div>
        <div className="mb-sidebar-menu">
          {menuData.map((el) => {
            return (
              <div className="mb-menu-item" key={el._id}>
                {el.type === "home" ? (
                  <Link to={"/"}>
                    <button className="mb-menu-button">
                      <p>
                        {el.menuName}
                        <i className="las la-plus"></i>
                      </p>
                    </button>
                  </Link>
                ) : el.type === "gallery" ? (
                  <Link to={"/gallery"}>
                    <button className="mb-menu-button">
                      <p>
                        {el.menuName}
                        <i className="las la-plus"></i>
                      </p>
                    </button>
                  </Link>
                ) : el.content ? (
                  <Link to={`/aboutData/${el._id}`}>
                    <button className="mb-menu-button">
                      <p>
                        {el.menuName}
                        <i className="las la-plus"></i>
                      </p>
                    </button>
                  </Link>
                ) : (
                  <Link to={"#"}>
                    <button className="mb-menu-button">
                      <p>
                        {el.menuName}
                        <i className="las la-plus"></i>
                      </p>
                    </button>
                  </Link>
                )}
                <div className="mb-menu-content show">
                  <ul className="list-unstyled">
                    {subMenuData?.map((elem) => {
                      if (elem.parent_id === el._id) {
                        return el.type === "faculties" ? (
                          <li className="nav-item" key={elem._id}>
                            {/* <Link
                              className="nav-link"
                              to={`/instructor/${elem.parent_id}`}
                            >
                              {elem.submenuName}
                            </Link> */}
                            {elem._id === "64fc6e77e2b037cfaf0c854c" ? ( // Principal Submenu Id
                              <Link
                                className="nav-link"
                                to={`/instructorDetails/64e854da6657751c63a4b52a`} // Principal Details
                              >
                                {elem.submenuName}
                              </Link>
                            ) : elem._id === "64fc6e86e2b037cfaf0c855e" ? ( // Voice Principal Submenu Id
                              <Link
                                className="nav-link"
                                to={`/instructorDetails/64eee41ce2b037cfaf0c63ea`} // Voice Principal Details
                              >
                                {elem.submenuName}
                              </Link>
                            ) : (
                              <Link
                                className="nav-link"
                                to={`/instructor/${elem.parent_id}`}
                              >
                                {elem.submenuName}
                              </Link>
                            )}
                          </li>
                        ) : el.type === "academics" ? (
                          <li className="nav-item" key={elem._id}>
                            <Link
                              className="nav-link"
                              to={`/academics/${elem._id}`}
                            >
                              {elem.submenuName}
                            </Link>
                          </li>
                        ) : el.type === "gallery" ? (
                          <li className="nav-item" key={elem._id}>
                            <Link className="nav-link" to={`/gallery`}>
                              {elem.submenuName}
                            </Link>
                          </li>
                        ) : (
                          <li className="nav-item" key={elem._id}>
                            <Link
                              className="nav-link"
                              to={`/common/${elem._id}`}
                            >
                              {elem.submenuName}
                            </Link>
                          </li>
                        );
                      }
                    })}
                    {/* <li className="nav-item">
                      <a
                        className="nav-link"
                        href={
                          "https://studentandfee.com/?school_id=studentandfee100"
                        }
                      >
                        ERP LOGIN
                      </a>
                    </li> */}
                    <button className="mb-menu-button">
                      <a
                        className="nav-link"
                        href={
                          "https://studentandfee.com/?school_id=studentandfee100"
                        }
                      >
                        ERP LOGIN
                      </a>
                    </button>
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <div className="mb-sidebar-overlay" id="mb-sidebar-overlay"></div>
    </Styles>
  );
}

export default MobileMenu;
