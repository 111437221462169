import React, { useState } from "react";
import NavBar from "../NavBar";
import "./Styles/Form.css";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Message() {
  const [inputData, setInputData] = useState({
    facultyName: "",
    file: "",
    message: "",
  });

  const navigate = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("facultyName", inputData.facultyName);
    formData.append("file", inputData.file);
    formData.append("message", inputData.message);

    try {
      await axios.post("https://chandrodaymsmd.org:5500/chandrodaymsmd/msg/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Data Added Successfully");
      navigate.push("/Home");
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div>
      <NavBar />
      <div className="w-50 login-box mt-5">
        <form className="container form" onSubmit={handleSubmit}>
          <h3 className="login-title">Faculty Message Form</h3>
          <div className="mb-3">
            <label for="exampleInputName" className="form-label">
              Faculty Message
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleInputName"
              onChange={(e) =>
                setInputData({ ...inputData, facultyName: e.target.value })
              }
              value={inputData.Name}
              required
            />
          </div>
          <div className="mb-3">
            <label for="exampleInputImage" className="form-label">
              Image
            </label>
            <div>
              <input
                type="file"
                name="file_upload"
                accept="image/*"
                onChange={(e) =>
                  setInputData({ ...inputData, file: e.target.files[0] })
                }
                required
              />
            </div>
          </div>
          <div className="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label">
              Message
            </label>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              onChange={(e) =>
                setInputData({ ...inputData, message: e.target.value })
              }
              value={inputData.Designation}
              required
            ></textarea>
          </div>
          <div className="mb-3">
            <div>
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Message;
