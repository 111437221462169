import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import MenuUpdate from "./Form/menuUpdate";
import SubMenuUpdate from "./Form/subMenuUpdate";
import ContentDec from "./Form/contentDec";
import FacultyUpdate from "./Form/facultyUpdate";
import MessageUpdate from "./Form/messageUpdate";
import FacultyDec from "./Form/facultyDec";
import SuccessStoryDes from "./Form/successStoryDes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NavBar from "./NavBar";
import "./styles/Content.css";

function Home() {
  const [menuDetails, setMenuDetails] = useState([]);
  const [subMenuDetails, setSubMenuDetails] = useState([]);
  const [contentDetails, setContentDetails] = useState([]);
  const [facultyDetails, setFacultyDetails] = useState([]);
  const [slider, setSlider] = useState([]);
  const [notice, setNotice] = useState([]);
  const [thought, setThought] = useState([]);
  const [successStory, setSuccessStory] = useState([]);
  const [message, setMessage] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [modalId, setModalId] = useState();
  const [subId, setSubId] = useState();
  const [conId, setConId] = useState();
  const [facultyId, setFacultyId] = useState();
  const [successStoryDesc, setSuccessStoryDesc] = useState();
  const [messsageId, setMesssageId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [subModal, setSubModal] = useState(false);
  const [parentId, setParentId] = useState();
  const [contentDec, setContentDec] = useState(false);
  const [facultyModal, setFacultyModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [facultyDec, setFacultyDec] = useState(false);
  const [successStoryModal, setSuccessStoryModal] = useState(false);
  const closeModle = () => setShowModal(false);
  const closeSubMenu = () => setSubModal(false);
  const closeContent = () => setContentDec(false);
  const closeFacultyModal = () => setFacultyModal(false);
  const closeMessageModal = () => setMessageModal(false);
  const closeFaculty = () => setFacultyDec(false);
  const closeSuccessStory = () => setSuccessStoryModal(false);

  const navigate = useHistory();

  function handleModal(val, id) {
    setShowModal(val);
    setModalId(id);
  }

  function handleSubModal(val, id, pid) {
    setSubModal(val);
    setSubId(id);
    setParentId(pid);
  }

  function handleFacultyModal(val, id) {
    setFacultyModal(val);
    setFacultyId(id);
  }

  function handleFacultyDes(val, id) {
    setFacultyDec(val);
    setFacultyId(id);
  }

  function handleSuccessStoryDesc(val, Desc) {
    setSuccessStoryModal(val);
    setSuccessStoryDesc(Desc);
  }

  function handleContentDes(val, id) {
    setContentDec(val);
    setConId(id);
  }

  function handleMessageModal(val, id) {
    setMessageModal(val);
    setMesssageId(id);
  }

  //get data of message
  const handleMessageDetails = async () => {
    try {
      await axios(
        "https://chandrodaymsmd.org:5500/chandrodaymsmd/msg/getMessage"
      ).then((result) => setMessage(result.data));
    } catch (error) { }
  };


  //get data of gallery
  const handleGalleryDetails = async () => {
    try {
      await axios(
        "https://chandrodaymsmd.org:5500/chandrodaymsmd/gallery/get"
      ).then((result) => setGallery(result.data.data));
    } catch (error) { }
  };

  // Get Data from the Menu
  const handleMenuDetails = async () => {
    try {
      await axios
        .get("https://chandrodaymsmd.org:5500/chandrodaymsmd/menu/getMenu")
        .then((result) => setMenuDetails(result.data))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  // Get Data from the Sub Menu
  const handleSubMenuDetails = async () => {
    try {
      await axios
        .get("https://chandrodaymsmd.org:5500/chandrodaymsmd/menu/merge/")
        .then((result) => setSubMenuDetails(result.data))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  // Get Data from the Content
  const handleContentDetails = async () => {
    try {
      await axios
        .get("https://chandrodaymsmd.org:5500/chandrodaymsmd/content/getMerge/")
        .then((result) => setContentDetails(result.data))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  // Get Data from the Faculty Menu
  const handlefacultyDetails = async () => {
    try {
      await axios
        .get("https://chandrodaymsmd.org:5500/chandrodaymsmd/faculty/get")
        .then((result) => setFacultyDetails(result.data.data))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  // Get Data from the Slider

  const handleSliderDetails = async () => {
    try {
      await axios
        .get("https://chandrodaymsmd.org:5500/chandrodaymsmd/slider/get")
        .then((result) => setSlider(result.data.data))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  //get Data from Notice
  const handleNotice = async () => {
    try {
      await axios.get("https://chandrodaymsmd.org:5500/chandrodaymsmd/notice/get")
        .then((result) => setNotice(result.data.data))
        .catch(err => console.log(err))
    } catch (error) {
      console.log(error)
    }
  }

  //get Data from Thought
  const handleThought = async () => {
    try {
      await axios.get("https://chandrodaymsmd.org:5500/chandrodaymsmd/thought/get")
        .then((result) => setThought(result.data.data))
        .catch(err => console.log(err))
    } catch (error) {
      console.log(error)
    }
  }

  //get Data from Success Story
  const handleSuccessStory = async () => {
    try {
      await axios.get("https://chandrodaymsmd.org:5500/chandrodaymsmd/successStory/get")
        .then((result) => setSuccessStory(result.data))
        .catch(err => console.log(err))
    } catch (error) {
      console.log(error)
    }
  }

  // Delete from the table of menu
  function handleDeleteMenu(_id) {
    const conf = window.confirm("Do you want to Delete");
    if (conf) {
      axios
        .delete(
          "https://chandrodaymsmd.org:5500/chandrodaymsmd/menu/deletemenu/" + _id
        )
        .then((res) => {
          handleMenuDetails();
        })
        .catch((err) => console.log(err));
    }
  }

  //handle Delete Notice 
  const handleDeleteNotice = (_id) => {
    const conf = window.confirm("Do you want to Delete");
    if (conf) {
      try {
        axios.delete(`https://chandrodaymsmd.org:5500/chandrodaymsmd/notice/delete/${_id}`)
          .then((res) => {
            handleNotice();
          })
      } catch (error) {
        console.log(error)
      }
    }
  }

  //handle Delete Thought 
  const handleDeleteThought = (_id) => {
    const conf = window.confirm("Do you want to Delete");
    if (conf) {
      try {
        axios.delete(`https://chandrodaymsmd.org:5500/chandrodaymsmd/thought/delete/${_id}`)
          .then((res) => {
            handleThought();
          })
      } catch (error) {
        console.log(error)
      }
    }
  }

  //handle Delete Success Story 
  const handleDeleteSuccessStory = (_id) => {
    const conf = window.confirm("Do you want to Delete");
    if (conf) {
      try {
        axios.delete(`https://chandrodaymsmd.org:5500/chandrodaymsmd/successStory/delete/${_id}`)
          .then((res) => {
            handleSuccessStory();
          })
      } catch (error) {
        console.log(error)
      }
    }
  }

  // Delete from the table of menu
  function handleDeleteSubMenu(_id) {
    const conf = window.confirm("Do you want to Delete");
    if (conf) {
      axios
        .delete(
          "https://chandrodaymsmd.org:5500/chandrodaymsmd/submenu/deleteSubmenu/" +
          _id
        )
        .then((res) => {
          handleSubMenuDetails();
        })
        .catch((err) => console.log(err));
    }
  }

  // delete message
  function handleDeleteMessage(id) {
    const conf = window.confirm("Do you want to delete");
    if (conf) {
      axios
        .delete(`https://chandrodaymsmd.org:5500/chandrodaymsmd/msg/delete/${id}`)
        .then((res) => {
          handleMessageDetails();
        });
    }
  }

  // delete message
  function handleDeleteGallery(id) {
    const conf = window.confirm("Do you want to delete");
    if (conf) {
      axios
        .delete(`https://chandrodaymsmd.org:5500/chandrodaymsmd/gallery/delete/${id}`)
        .then((res) => {
          handleGalleryDetails();
        });
    }
  }

  // Delete from the table of Content
  function handleDeleteContent(_id) {
    const conf = window.confirm("Do you want to Delete");
    if (conf) {
      axios
        .delete(
          "https://chandrodaymsmd.org:5500/chandrodaymsmd/content/delete/" + _id
        )
        .then((res) => {
          handleContentDetails();
        })
        .catch((err) => console.log(err));
    }
  }

  // Delete from the table of Faculty

  function handleDeleteFaculty(_id) {
    const conf = window.confirm("Do you want to Delete");
    if (conf) {
      axios
        .delete(
          "https://chandrodaymsmd.org:5500/chandrodaymsmd/faculty/delete/" + _id
        )
        .then((res) => {
          handlefacultyDetails();
        })
        .catch((err) => console.log(err));
    }
  }

  // Delete from the table of Slider

  function handleDeleteSlider(_id) {
    const conf = window.confirm("Do you want to Delete");
    if (conf) {
      axios
        .delete(
          "https://chandrodaymsmd.org:5500/chandrodaymsmd/slider/delete/" + _id
        )
        .then((res) => {
          handleSliderDetails();
        })
        .catch((err) => console.log(err));
    }
  }

  //---------------------Start Filter of Table------------------------
  function filterSelection(c) {
    var x, i;
    x = document.getElementsByClassName("filterDiv");
    if (c == "all") c = "";
    for (i = 0; i < x.length; i++) {
      RemoveClass(x[i], "show");
      if (x[i].className.indexOf(c) > -1) AddClass(x[i], "show");
    }
  }

  function AddClass(element, name) {
    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
      if (arr1.indexOf(arr2[i]) == -1) { element.className += " " + arr2[i]; }
    }
  }

  function RemoveClass(element, name) {
    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
      while (arr1.indexOf(arr2[i]) > -1) {
        arr1.splice(arr1.indexOf(arr2[i]), 1);
      }
    }
    element.className = arr1.join(" ");
  }

  useEffect(() => {
    filterSelection("all");

    // Add active class to the current button (highlight it)
    var btnContainer = document.getElementById("myBtnContainer");
    var btns = btnContainer.getElementsByClassName("filterbtn");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {

        // When clicking this button then refresh the search field
        setSearch("");
        // setFocusText();

        var current = document.getElementsByClassName("active");
        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
      });
    }
  }, []);
  //---------------------End Filter of Table------------------------

  //-----------------------Start Table Filter------------------------
  const [search, setSearch] = useState('');
  const handleSearch = (event) => {
    setSearch(event);
  };

  // filter menu items
  const menuData = {
    nodes: menuDetails.filter((item) =>
      item.menuName.toLowerCase().includes(search.toLowerCase())
    ),
  };

  // filter sub-menu items
  const subMenuData = {
    nodes: subMenuDetails.filter((item) =>
      item.menuName.toLowerCase().includes(search.toLowerCase())
    ),
  };

  // filter sub-menu items
  const contentData = {
    nodes: contentDetails.filter((item) =>
      item.submenuName.toLowerCase().includes(search.toLowerCase())
        ? item.submenuName.toLowerCase().includes(search.toLowerCase())
        : item.contentHeading.toLowerCase().includes(search.toLowerCase())
    ),
  };

  // filter Faculty items
  const facultyData = {
    nodes: facultyDetails.filter((item) =>
      item.Name.toLowerCase().includes(search.toLowerCase())
        ? item.Name.toLowerCase().includes(search.toLowerCase())
        : item.Designation.toLowerCase().includes(search.toLowerCase())
          ? item.Designation.toLowerCase().includes(search.toLowerCase())
          : item.Qualification.toLowerCase().includes(search.toLowerCase())
            ? item.Qualification.toLowerCase().includes(search.toLowerCase())
            : item.Contact[0].Email.toLowerCase().includes(search.toLowerCase())
    ),
  };

  // filter message items
  const messageData = {
    nodes: message.filter((item) =>
      item.facultyName.toLowerCase().includes(search.toLowerCase())
    ),
  };

  // filter Gallery items
  const galleryData = {
    nodes: gallery.filter((item) =>
      item.galleryDesc?.toLowerCase().includes(search.toLowerCase())
        ? item.galleryDesc?.toLowerCase().includes(search.toLowerCase())
        : item.category?.toLowerCase().includes(search.toLowerCase())
    ),
  };

  // filter notice items
  const noticeData = {
    nodes: notice.filter((item) =>
      item.noticeDesc.toLowerCase().includes(search.toLowerCase())
        ? item.noticeDesc.toLowerCase().includes(search.toLowerCase())
        : item.noticeDate.toLowerCase().includes(search.toLowerCase())
    ),
  };

  // filter Faculty items
  const successStoryData = {
    nodes: successStory.filter((item) =>
      item.Title.toLowerCase().includes(search.toLowerCase())
        ? item.Title.toLowerCase().includes(search.toLowerCase())
        : item.Author_Name.toLowerCase().includes(search.toLowerCase())
          ? item.Author_Name.toLowerCase().includes(search.toLowerCase())
          : item.Author_Designation.toLowerCase().includes(search.toLowerCase())
    ),
  };
  //-----------------------Start Table Filter------------------------

  useEffect(() => {
    handleMenuDetails();
    handleSubMenuDetails();
    handleContentDetails();
    handlefacultyDetails();
    handleSliderDetails();
    handleMessageDetails();
    handleGalleryDetails();
    handleNotice();
    handleThought();
    handleSuccessStory();
  }, []);

  return (
    <div className="content-wrapper">
      {/* NavBar */}
      <NavBar />
      <div className="container-fluid">
        <div className="card shadow my-4 p-2">
          <h3 className="m-0" style={{ textAlign: "center" }}>
            Dashboard
          </h3>
        </div>
      </div>
      {/* /.container-fluid */}

      <div className="container">
        <div className="card shadow my-4 p-2" style={{ backgroundColor: '#94ADD7' }}>
          <div id="myBtnContainer" className="text-center">
            <button className="btn filterbtn active" onClick={() => filterSelection('all')}> Show all</button>
            <button className="btn filterbtn" onClick={() => filterSelection('menu')}> MENUS</button>
            <button className="btn filterbtn" onClick={() => filterSelection('subMenu')}> SUB-MENUS</button>
            <button className="btn filterbtn" onClick={() => filterSelection('content')}> CONTENTS</button>
            <button className="btn filterbtn" onClick={() => filterSelection('faculties')}> FACULTIES</button>
            <button className="btn filterbtn" onClick={() => filterSelection('slider')}> SLIDERS</button>
            <button className="btn filterbtn" onClick={() => filterSelection('message')}> MESSAGES</button>
            <button className="btn filterbtn" onClick={() => filterSelection('gallery')}> GALLERY</button>
            <button className="btn filterbtn" onClick={() => filterSelection('notice')}> Notice</button>
            <button className="btn filterbtn" onClick={() => filterSelection('thought')}> Thought</button>
            <button className="btn filterbtn" onClick={() => filterSelection('successStory')}> Success-Story</button>
          </div>
        </div>
      </div>
      {/* /.container-fluid */}

      {/* Serch Filter Table */}
      <div className="container mb-3">
        <input type="text" className="form-control" value={search} onChange={(e) => handleSearch(e.target.value)} placeholder="Search...." autoFocus />
      </div>

      {/* Main content */}
      <section className="content fs-5">
        <div className="container-fluid">
          <section className="container connectedSortable tables">
            {/* Custom tabs for Menu)*/}
            <div className="filterDiv menu">
              <div className="card shadow mb-4">
                <div className="card-header">
                  <h3 className="card-title">Menu Table</h3>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <div className="rounded">
                    <div className="tbldiv">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Menu Name</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            // loop over products
                            menuData.nodes.map((data, i) => {
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{data.menuName}</td>
                                  <td>{data.date.slice(0, 10)}</td>
                                  <td>
                                    <div className="row">
                                      <div
                                        className="col"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        {data.menuName === "Home" ? (
                                          <button className="form-control btn btn-warning btn-sm">
                                            can't update
                                          </button>
                                        ) : (
                                          <button
                                            className="form-control btn btn-warning btn-sm"
                                            onClick={() =>
                                              handleModal(true, data._id)
                                            }
                                          >
                                            Edit
                                          </button>
                                        )}
                                      </div>
                                      <div className="col">
                                        {data.type ? (
                                          <button
                                            className="form-control btn btn-danger btn-sm"
                                            disabled
                                          >
                                            can't delete
                                          </button>
                                        ) : (
                                          <button
                                            className="form-control btn btn-danger btn-sm"
                                            onClick={(e) =>
                                              handleDeleteMenu(data._id)
                                            }
                                          >
                                            Delete
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* /.card-body */}
              </div>
            </div>
            {/* /.Menu End */}

            {/* Custom tabs for Sub Menu)*/}
            <div className="filterDiv subMenu">
              <div className="card shadow mb-4">
                <div className="card-header">
                  <h3 className="card-title">Sub-Menu Table</h3>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <div className="rounded">
                    <div className="p-3 tbldiv">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Menu Name</th>
                            <th>Sub-Menu Name</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subMenuData.nodes.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{data.menuName}</td>
                              <td>{data.submenuName}</td>
                              <td>{data.date.slice(0, 10)}</td>
                              <td>
                                <div className="row">
                                  <div
                                    className="col"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    {/* <Link to={`/SubMenuUpdate/${data._id}`} type='button' className='form-control btn btn-warning btn-sm'>Edit</Link> */}
                                    <button
                                      className="form-control btn btn-warning btn-sm"
                                      onClick={() =>
                                        handleSubModal(true, data._id, data.parent_id)
                                      }
                                    >
                                      Edit
                                    </button>
                                  </div>
                                  <div className="col">
                                    <button
                                      className="form-control btn btn-danger btn-sm"
                                      onClick={(e) =>
                                        handleDeleteSubMenu(data._id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* /.card-body */}
              </div>
            </div>
            {/* /.Sub Menu End */}

            {/* Custom tabs for Content)*/}
            <div className="filterDiv content">
              <div className="card shadow mb-4">
                <div className="card-header">
                  <h3 className="card-title">Content Table</h3>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <div className="rounded">
                    <div className="p-3 tbldiv">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Sub Menu</th>
                            <th>Heading</th>
                            <th>Image</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contentData.nodes.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{data.submenuName}</td>
                              <td>{data.contentHeading}</td>
                              <td>
                                {data.contentImage
                                  .split(".")
                                  .pop()
                                  .toLowerCase() === "pdf" ? (
                                  <a
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                    }}
                                    target="blank"
                                    href={`https://chandrodaymsmd.org:5500/${data.contentImage}`}
                                  >
                                    OPEN PDF
                                  </a>
                                ) : (
                                  <a
                                    href={`https://chandrodaymsmd.org:5500/${data.contentImage}`}
                                  >
                                    <img
                                      width="55"
                                      height="60"
                                      src={`https://chandrodaymsmd.org:5500/${data.contentImage}`}
                                      alt="Error"
                                    />
                                  </a>
                                )}
                              </td>
                              <td>{data.contentDate.slice(0, 10)}</td>
                              <td>
                                <div className="row">
                                  <div
                                    className="col"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <button
                                      className="form-control btn btn-primary btn-sm"
                                      onClick={() =>
                                        handleContentDes(true, data._id)
                                      }
                                    >
                                      Description
                                    </button>
                                  </div>
                                  <div
                                    className="col"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <Link
                                      to={`/ContentUpdate/${data._id}`}
                                      type="button"
                                      className="form-control btn btn-warning btn-sm"
                                    >
                                      Edit
                                    </Link>
                                  </div>
                                  <div className="col">
                                    <button
                                      className="form-control btn btn-danger btn-sm"
                                      onClick={(e) =>
                                        handleDeleteContent(data._id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* /.card-body */}
              </div>
            </div>
            {/* /.Content End */}

            {/* Custom tabs for Faculty)*/}
            <div className="filterDiv faculties">
              <div className="card shadow mb-4">
                <div className="card-header">
                  <h3 className="card-title">Faculties Table</h3>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <div className="rounded">
                    <div className="p-3 tbldiv">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Faculty Name</th>
                            <th>Image</th>
                            <th>Designation</th>
                            <th>Qualification</th>
                            <th>Email</th>
                            <th>Menu Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {facultyData.nodes.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{data.Name}</td>
                              <td>
                                <img
                                  width="65"
                                  src={`https://chandrodaymsmd.org:5500/${data.Image}`}
                                  alt="Error"
                                />
                              </td>
                              <td>{data.Designation}</td>
                              <td>{data.Qualification}</td>
                              <td>{data.Contact[0].Email}</td>
                              <td>{data.EntryDate.slice(0, 10)}</td>
                              <td>
                                <div className="row">
                                  <div
                                    className="col"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <button
                                      className="form-control btn btn-primary btn-sm"
                                      onClick={() =>
                                        handleFacultyDes(true, data._id)
                                      }
                                    >
                                      Description
                                    </button>
                                  </div>
                                  <div
                                    className="col"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <button
                                      className="form-control btn btn-warning btn-sm"
                                      onClick={() =>
                                        handleFacultyModal(true, data._id)
                                      }
                                    >
                                      Edit
                                    </button>
                                  </div>
                                  <div className="col">
                                    <button
                                      className="form-control btn btn-danger btn-sm"
                                      onClick={(e) =>
                                        handleDeleteFaculty(data._id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* /.card-body */}
              </div>

            </div>
            {/* /.Faculty End */}

            {/* Custom tabs for Slider)*/}
            <div className="filterDiv slider">
              <div className="card shadow mb-4">
                <div className="card-header">
                  <h3 className="card-title">Slider Table</h3>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <div className="rounded">
                    <div className="p-3 tbldiv">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Image</th>
                            <th>Content</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {slider.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>
                                <img
                                  width="65"
                                  src={`https://chandrodaymsmd.org:5500/${data.sliderImage}`}
                                  alt="img not upload"
                                />
                              </td>
                              <td>{data.sliderContent}</td>
                              <td>{data.sliderDate.slice(0, 10)}</td>
                              <td>
                                <div className="row">
                                  <div className="col">
                                    <button
                                      className="form-control btn btn-danger btn-sm"
                                      onClick={(e) =>
                                        handleDeleteSlider(data._id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* /.card-body */}
              </div>
            </div>
            {/* /.Slider End */}

            {/* Custom tabs for Message */}
            <div className="filterDiv message">
              <div className="card shadow mb-4">
                <div className="card-header">
                  <h3 className="card-title">Message Table</h3>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <div className="rounded">
                    <div className="p-3 tbldiv">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Image</th>
                            <th>Faculty Name</th>
                            <th>Message</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {messageData.nodes.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>
                                <img
                                  width="65"
                                  src={`https://chandrodaymsmd.org:5500/${data.image}`}
                                  alt="img not upload"
                                />
                              </td>

                              <td>{data.facultyName}</td>
                              <td>{data.message}</td>
                              <td>{data.date.slice(0, 10)}</td>
                              <td>
                                <div className="row">
                                  <div
                                    className="col"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <button
                                      className="form-control btn btn-warning btn-sm"
                                      onClick={() =>
                                        handleMessageModal(true, data._id)
                                      }
                                    >
                                      Edit
                                    </button>
                                  </div>
                                  <div className="col">
                                    <button
                                      className="form-control btn btn-danger btn-sm"
                                      onClick={(e) =>
                                        handleDeleteMessage(data._id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* /.card-body */}
              </div>
            </div>
            {/* Custom tabs for Message */}

            {/* Custom tabs for Gallery)*/}
            <div className="filterDiv gallery">
              <div className="card shadow mb-4">
                <div className="card-header">
                  <h3 className="card-title">Gallery Table</h3>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <div className="rounded">
                    <div className="p-3 tbldiv">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Image</th>
                            <th>Category</th>
                            <th>Description</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {galleryData.nodes.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>
                                <img
                                  width="65"
                                  src={`https://chandrodaymsmd.org:5500/${data.galleryImage}`}
                                  alt="img not upload"
                                />
                              </td>
                              <td>{data.category}</td>
                              <td>{data.galleryDesc}</td>
                              <td>{data.galleryDate?.slice(0, 10)}</td>
                              <td>
                                <div className="row">
                                  <div className="col">
                                    <button
                                      className="form-control btn btn-danger btn-sm"
                                      onClick={(e) =>
                                        handleDeleteGallery(data._id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* /.card-body */}
              </div>
            </div>
            {/* /.Slider End */}

            {/* Custom tabs for Notice Table)*/}
            <div className="filterDiv notice">
              <div className="card shadow mb-4">
                <div className="card-header">
                  <h3 className="card-title">Notice Table</h3>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <div className="rounded">
                    <div className="p-3 tbldiv">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>PDF</th>
                            <th>Description</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {noticeData.nodes.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>
                                {data.noticePdf ?
                                  <a
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                      fontSize: "13px",
                                    }}
                                    target="_blank"
                                    href={`https://chandrodaymsmd.org:5500/${data.noticePdf}`}
                                  >
                                    OPEN PDF
                                  </a>
                                  : <div
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Not Uploaded
                                  </div>}
                              </td>
                              <td>{data.noticeDesc}</td>
                              <td>{data.noticeDate?.slice(0, 10)}</td>
                              <td>
                                <div className="row">
                                  <div className="col">
                                    <button
                                      className="form-control btn btn-danger btn-sm"
                                      onClick={(e) =>
                                        handleDeleteNotice(data._id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* /.card-body */}
              </div>
            </div>

            {/* Custom tabs for Notice Table)*/}
            <div className="filterDiv thought">
              <div className="card shadow mb-4">
                <div className="card-header">
                  <h3 className="card-title">Thought Table</h3>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <div className="rounded">
                    <div className="p-3 tbldiv">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Thought</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {thought.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{data.thought}</td>
                              <td>{data.thoughtDate?.slice(0, 10)}</td>
                              <td>
                                <div className="row">
                                  <div className="col">
                                    <button
                                      className="form-control btn btn-danger btn-sm"
                                      onClick={(e) =>
                                        handleDeleteThought(data._id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* /.card-body */}
              </div>
            </div>

            {/* Custom tabs for Success Story Table)*/}
            <div className="filterDiv successStory">
              <div className="card shadow mb-4">
                <div className="card-header">
                  <h3 className="card-title">Success Story Table</h3>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <div className="rounded">
                    <div className="p-3 tbldiv">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Title</th>
                            <th>Author_Name</th>
                            <th>Author_Image</th>
                            <th>Author_Designation</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {successStoryData.nodes.map((data, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{data.Title}</td>
                              <td>{data.Author_Name}</td>
                              <td>
                                <img
                                  width="65"
                                  src={`https://chandrodaymsmd.org:5500/${data.Author_Image}`}
                                  alt="img not upload"
                                />
                              </td>
                              <td>{data.Author_Designation}</td>
                              <td>{data.Desc.slice(0, 20)}...</td>
                              <td>
                                <div className="row">
                                  <div
                                    className="col"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <button
                                      className="form-control btn btn-primary btn-sm"
                                      onClick={() =>
                                        handleSuccessStoryDesc(true, data.Desc)
                                      }
                                    >
                                      Description
                                    </button>
                                  </div>
                                  <div
                                    className="col"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <Link
                                      to={`/SuccessStory/${data._id}`}
                                      type="button"
                                      className="form-control btn btn-warning btn-sm"
                                    >
                                      Edit
                                    </Link>
                                  </div>
                                  <div className="col">
                                    <button
                                      className="form-control btn btn-danger btn-sm"
                                      onClick={(e) =>
                                        handleDeleteSuccessStory(data._id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* /.card-body */}
              </div>
            </div>

          </section>

          {showModal && <MenuUpdate id={modalId} closeModle={closeModle} />}
          {subModal && (
            // <SubMenuUpdate id={subId} closeSubMenu={closeSubMenu} />
            <SubMenuUpdate
              pid={parentId}
              id={subId}
              closeSubMenu={closeSubMenu}
            />
          )}
          {contentDec && (
            <ContentDec id={conId} closeContent={closeContent} />
          )}
          {facultyModal && (
            <FacultyUpdate
              id={facultyId}
              closeFacultyModal={closeFacultyModal}
            />
          )}
          {messageModal && (
            <MessageUpdate
              id={messsageId}
              closeMessageModal={closeMessageModal}
            />
          )}
          {facultyDec && (
            <FacultyDec id={facultyId} closeFaculty={closeFaculty} />
          )}
          {successStoryModal && (
            <SuccessStoryDes desc={successStoryDesc} closeSuccessStory={closeSuccessStory} />
          )}
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.content */}
    </div>
  );
}

export default Home;
