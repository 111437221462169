import React, { useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { Styles } from "./Style/Academics";
import axios from "axios";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";

function Academics() {
  const { menuId } = useParams();
  // const id = menuId.split(":")[0];
  const [pdfData, setPdfData] = useState([]);

  useMemo(async () => {
    // console.log(menuId);
    await axios
      .get(
        `https://chandrodaymsmd.org:5500/chandrodaymsmd/content/get/${menuId}`
      )
      .then((result) => {
        setPdfData(result.data.data);
        // console.log(result.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [menuId]);

  return (
    <>
      <Styles>
        {/* Header  */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Academics" />

        <section className="academics-pdf">
          <Container>
            <div className="shadow my-5 p-3">
              <h4 className="text-center mb-3">CPS Academics</h4>
              <Table striped hover>
                <tbody>
                  {pdfData.map((data, index) => {
                    return (
                      <tr key={index}>
                        <th>{index + 1}</th>
                        <th>{data.contentHeading}</th>
                        <td>
                          <a
                            href={`https://chandrodaymsmd.org:5500/${data.contentImage}`}
                            className="btn btn_pdf"
                            target="_blank"
                          >
                            Open PDF
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Container>
        </section>
      </Styles>
    </>
  );
}

export default Academics;
