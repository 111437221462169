import React, { Component } from "react";
import HeroSlider from "./components/HeroSlider";
import IconBox from "./components/IconBox";
import Notice_Circular from "./components/Notice_Circular";
import AboutUs from "./components/AboutUs";
import FaqEvent from "./components/FaqEvent";
import HelpArea from "./components/HelpArea";
import HomeBlog from "./components/HomeBlog";
import Header from "./components/Header";
import TestimonialSlider from './components/TestimonialSlider';

export default class HomeOne extends Component {
  render() {
    return (
      <div className="main-wrapper">

        {/* Header */}
        <Header />

        {/* Hero Slider */}
        <HeroSlider />

        {/* Icon Box */}
        <IconBox />

        {/* Notice/Circular */}
        <Notice_Circular />

        {/* About Area */}
        <AboutUs />

        {/* TestimonialSlider */}
        <TestimonialSlider />

        {/* Faq & Event Area */}
        <FaqEvent />

        {/* Help Area */}
        <HelpArea />

        {/* Blog Area */}
        <HomeBlog />
      </div>
    );
  }
}
