import React, { useState, useEffect } from "react";
import NavBar from "../NavBar";
import "./Styles/Form.css";
import axios from "axios";
import { useHistory } from "react-router-dom";

function Notice() {
    const [file, setFile] = useState("");
    const [noticeDesc, setNoticeDesc] = useState("");
    const navigate = useHistory();

    // Submite the data of the Gallery
    const onSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", file);
        formData.append("noticeDesc", noticeDesc);

        try {
            await axios.post("https://chandrodaymsmd.org:5500/chandrodaymsmd/notice/add", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            alert("Data Added Successfully");
            navigate.push("/Home");
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <NavBar />
            <section className="container">
                <div className="w-50 login-box mt-5">
                    <form className="container form" onSubmit={onSubmit}>
                        <h3 className="login-title">Notice-Form</h3>

                        <div className="mb-3">
                            <label htmlFor="exampleInputImage" className="form-label">
                                PDF
                            </label>
                            <div>
                                <input type="file" onChange={(e) => { setFile(e.target.files[0]) }} />
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="exampleInputDescription" className="form-label">
                                Description
                            </label>
                            <textarea type="text" value={noticeDesc} className="form-control" id="exampleInputDescription" onChange={(e) => setNoticeDesc(e.target.value)} required />
                        </div>

                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </form>
                </div>
            </section>
        </div>
    )
}

export default Notice;