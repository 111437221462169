import React, { Component } from "react";
import StickyMenu from "./common/StickyMenu";
import MobileMenu from "./common/MobileMenu";
import TopBar from "./TopBar";

class Header extends Component {
  render() {
    return (
      <>
        {/* TopBar */}
        <TopBar />

        {/* Sticky Menu */}
        <StickyMenu />

        {/* Mobile Menu */}
        <MobileMenu />
      </>
    );
  }
}

export default Header;
