import styled from "styled-components";
import { colors } from "../element/elements.js";

export const Styles = styled.div`
  .sticky-menu {
    position: absolute;
    top: 36px;
    left: 0;
    width: 100%;
    z-index: 11;
    animation: 300ms ease-in-out 0s normal none 1 fadeInDown;
    background: #182b49;
    height: 110px;
    padding-top: 12px;
    opacity: 0.7;
    ${"" /* box-shadow: 0 0 20px -10px #222222; */}

    @media (max-width: 1199px) {
      max-width: 100%;
    }

    .logo {
      margin-top: -3px;
      a {
        display: flex;
        img {
          width: 7.2rem;
          height: 7.5rem;
          padding-bottom: 10px;
          @media (max-width: 1300px) and (min-width: 1110px) {
            width: 7rem;
            height: 7rem;
            padding-top: 10px;
          }
          @media (max-width: 1110px) and (min-width: 767px) {
            width: 6rem;
            margin-top: 15px;
            ${'' /* margin-bottom: auto; */}
            height: 6rem;
          }
        }
        .cps_name {
          display: inline;
          font-weight: 700;
          font-family: 'Libre Baskerville', serif;
          text-transform: uppercase;
          color: #ffffff;
          text-align: center;

          .cps_n{
            font-size: 2.4rem;
            margin-bottom: -0.8rem;
            letter-spacing: 1px;

            @media (max-width: 1300px) and (min-width: 1110px) {
            font-size: 2.2rem;
            margin-top: 0.4rem;
            }
            
            @media (max-width: 1110px) and (min-width: 767px) {
              margin-top: 1.4rem;
              font-size: 2rem;
              ${'' /* margin-bottom: -1rem; */}
            }
          }

          span.spanCPS{
            letter-spacing: 2px;
            font-size: 1.4rem;

            @media (max-width: 1300px) and (min-width: 1110px) {
            font-size: 1.2rem;
          }
          @media (max-width: 1110px) and (min-width: 767px) {
            font-size: 1rem;
          }

            }

          &:hover {
            color: ${colors.green};
          }

          @media (max-width: 1300px) and (min-width: 1120px) {
            font-size: 1.4rem;
          }
          @media (max-width: 1120px) and (min-width: 767px) {
            font-size: 1rem;
          }

          .affiliated{
            display: flex;
            flex-direction: column;
            text-align: center;
            font-size: 1rem;

            .affiliatedSpan{
              padding-left: 2px;
            }

            @media (max-width: 1300px) and (min-width: 1110px) {
            font-size: 0.8rem;
          }
          @media (max-width: 1110px) and (min-width: 767px) {
            font-size: 0.7rem;
          }
          }
        }
      }
    }

    div.menu-box {
      margin-top: 15px;
      ul.nav.menu-nav {
        li.nav-item {
          position: relative;
          a.nav-link {
            font-size: 14px;
            color: #ffffff;
            text-transform: uppercase;
            font-weight: 900;
            padding: 8px 8px 14px;

            i {
              font-size: 15px;
            }

            &:after {
              content: none;
            }

            &:hover {
              color: ${colors.green};
            }

            @media (max-width: 1300px) and (min-width: 767px) {
              font-size: 12px;
              font-weight: 800;
            }
            @media (max-width: 1120px) and (min-width: 767px) {
              font-size: 10px;
              font-weight: 700;
            }
          }

          ul.dropdown {
            position: absolute;
            left: 0;
            top: 100%;
            min-width: 190px;
            background: #fff;
            border: 1px solid ${colors.border1};
            text-align: left;
            padding: 0;
            border-radius: 5px;
            transition: 0.2s ease;
            opacity: 0;
            transform: scaleY(0);
            visibility: hidden;
            z-index: 999;
            transform-origin: center top 0;

            li.nav-item {
              position: relative;

              a.nav-link {
                font-size: 13px;
                color: ${colors.text1};
                padding: 10px 20px;
                text-transform: capitalize;
                font-weight: 400;
                margin-right: 0;
                border-bottom: 1px solid ${colors.border1};

                &:hover {
                  color: ${colors.green};

                  i {
                    color: #fff;
                  }
                }

                i {
                  float: right;
                  margin-top: 4px;
                }
              }

              &:last-child {
                margin-left: 0;

                a.nav-link {
                  border-bottom: none;
                }
              }

              ul.dropdown2 {
                position: absolute;
                top: 0;
                left: 100%;
                min-width: 180px;
                background: #fff;
                border: 1px solid ${colors.border1};
                transition: 0.2s ease;
                opacity: 0;
                transform: scaleY(0);
                visibility: hidden;
                z-index: 999;
                transform-origin: center top 0;
              }

              &:hover {
                ul.dropdown2 {
                  opacity: 1;
                  transform: scaleY(1);
                  visibility: visible;
                }
              }
            }
          }

          &:hover {
            ul.dropdown {
              opacity: 1;
              transform: scaleY(1);
              visibility: visible;
            }
          }
        }
      }

      .apply-btn {
        margin-left: 70px;
        margin-top: -1px;

        a {
          font-size: 13px;
          color: #ffffff;
          background: ${colors.gr_bg};
          display: inline-block;
          width: 110px;
          height: 40px;
          text-align: center;
          text-transform: uppercase;
          font-weight: 500;
          padding: 11px;
          border-radius: 0 5px 5px 0;
          position: relative;

          i {
            position: absolute;
            font-size: 20px;
            left: -40px;
            top: 0;
            padding: 10px;
            width: 40px;
            height: 100%;
            border-radius: 5px 0 0 5px;
            background-color: ${colors.green2};
          }

          &:hover {
            background: ${colors.gr_bg2};
          }
        }

        @media (max-width: 1199px) {
          margin-left: 35px;
        }

        @media (max-width: 1004px) {
          display: none;
        }
      }
    }
    @media (max-width: 850px) {
      display: none;
    }
  }

  .sticky-menu.sticky {
    display: block;
    position: fixed;
    z-index: 1100;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9220063025210083) 98%,
      rgba(24, 43, 73, 1) 100%,
      rgba(255, 255, 255, 0.9220063025210083) 100%
    );
    box-shadow: 0px 1px 10px 5px rgba(24,43,73,0.1);
    top: 0;
    max-width: 100%;

    .logo {
      a {
        .cps_name {
          color: rgb(24, 43, 73);
          &:hover {
            color: ${colors.green};
          }
        }
      }
    }

    div.menu-box {
      ul.nav.menu-nav {
        li.nav-item {
          position: relative;
          a.nav-link {
            color: #242625;

            &:hover {
              color: ${colors.green};
            }
          }
        }
      }

      @media (max-width: 767px) {
        display: none;
      }
    }
    @media (max-width: 850px) {
      display: none;
    }
  }
`;
