import React, { Component } from "react";
// import Datas from "../data/blog/home-blog.json";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/homeBlog.js";
import axios from "axios";

class HomeBlog extends Component {

  state = {
    message: [],
  };

  facultyMessage = async () => {
    // console.log("Starting")
    await axios
      .get("https://chandrodaymsmd.org:5500/chandrodaymsmd/msg/getMessage")
      .then((result) => {
        this.setState({ message: result.data });
        // console.log(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.facultyMessage();
  }


  render() {
    return (
      <Styles>
        {/* Blog Area */}
        <section className="home-blog-area">
          <Container style={{ maxWidth: "90%" }}>
            <Row>
              <Col md="12">
                <div className="sec-title text-center">
                  <h4>Messages & Testimonials</h4>
                </div>
              </Col>
              {this.state.message.map((data, i) => (
                <Col md="6" key={i}>
                  {/* {console.log(data)} */}
                  <div className="blog-post">
                    <Row>
                      <Col lg="6" md="12">
                        <div className="blog-img">
                          <img
                            src={`https://chandrodaymsmd.org:5500/${data.image}`}
                            alt="Faculty Image"
                          />
                        </div>
                      </Col>
                      <Col lg="6" md="12">
                        <div className="blog-content">
                          <div className="content-box">
                            <div className="top-content d-flex">
                              <div className="blog-title">
                                <h6>
                                  <p>{data.message}</p>
                                </h6>
                              </div>
                            </div>
                            <div className="blog-desk">
                              <p>{data.facultyName}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default HomeBlog;
