import styled from "styled-components";
import { colors } from "../../../components/common/element/elements";

export const Styles = styled.div`
  .academics-pdf {
    padding-top: 1rem;
  }

  .table {
    font-size: 16px;

    td{
    text-align: center;
  }}

  .btn_pdf {
    background-color: ${colors.green};
    color: #ffffff;

    &:hover {
      background-color: ${colors.green2};
    }
  }
`;
