import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Swiper from "react-id-swiper";
import { Styles } from "./styles/testimonialSlider.js";
import axios from "axios";

// class TestimonialSlider extends Component {
//   state = {
//     TestimonialDetails: [],
//     flag : false,
//   };

const settings = {
  slidesPerView: 2,
  loop: true,
  speed: 1000,
  shouldSwiperUpdate: true,
  rebuildOnUpdate:true,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  spaceBetween: 30,
  watchSlidesVisibility: true,
  pagination: {
    el: ".slider-dot.text-center",
    clickable: true,
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
    },
    576: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 2,
    },
  },
};

function TestimonialSlider() {
  const [testimonialDetails, setTestimonialDetails] = useState([]);

  const handleTestimonialDetails = async () => {
    try {
      await axios
        .get("https://chandrodaymsmd.org:5500/chandrodaymsmd/successStory/get")
        .then((result) => {
          // this.setState({ TestimonialDetails: result.data })
          setTestimonialDetails(result.data);
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleTestimonialDetails();
  }, []);

  return (
    <Styles>
      {/* Testimonial Slider */}
      <section className="testimonial-area">
        <Container>
          <Row>
            <Col md="12" style={{ position: "relative" }}>
              <div className="sec-title text-center">
                <h4>Our Success Story</h4>
              </div>
            </Col>
            <Col md="12" className="testimonial-slider">
              <Swiper {...settings}>
                {testimonialDetails?.map((data, i) => (
                  <div className="slider-item" key={i}>
                    <div className="desc">
                      <h5>{data.Title}</h5>
                      <p>{data.Desc}</p>
                    </div>
                    <div className="writer">
                      <img
                        src={`https://chandrodaymsmd.org:5500/${data.Author_Image}`}
                        className="slider-image"
                        alt="error"
                      />
                      <h6>{data.Author_Name}</h6>
                      <p>{data.Title}</p>
                    </div>
                  </div>
                ))}
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}
// }

export default TestimonialSlider;
