import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Styles/Modal.css";

const FacultyDec = ({ id, closeFaculty }) => {
  const [values, setValues] = useState({
    Description: "",
  });

  function handleClose() {
    closeFaculty();
  }

  // Get Faculty Detailes

  useEffect(() => {
    axios
      .get("https://chandrodaymsmd.org:5500/chandrodaymsmd/faculty/get/" + id)
      .then((res) => {
        setValues({
          ...values,
          Description: res.data.data.Description,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="w-50 login-box  mt-5 modal-container">
        <div className="mb-3">
          <div className="fa" onClick={handleClose}>
            {" "}
            &#xf00d;
          </div>
          <h3 className="login-title">Description</h3>
          {values.Description}
        </div>
      </div>
    </>
  );
};

export default FacultyDec;
