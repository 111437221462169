import React, { useEffect } from "react";
import Datas from "../data/faq-event/faq-event.json";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/faqEvent.js";

function FaqEvent() {
  useEffect(() => {
    const accordionButton = document.querySelectorAll(".accordion-button");
    accordionButton.forEach((button) => {
      button.childNodes[0].childNodes[0].style.display = "none";
      button.addEventListener("click", () => {
        button.classList.toggle("active");
        const content = button.nextElementSibling;

        if (button.classList.contains("active")) {
          content.className = "accordion-content show";
          button.childNodes[0].childNodes[0].style.display = "none";
          button.childNodes[0].childNodes[1].style.display = "";
          content.style.maxHeight = content.scrollHeight + "px";
        } else {
          content.className = "accordion-content";
          button.childNodes[0].childNodes[0].style.display = "";
          button.childNodes[0].childNodes[1].style.display = "none";
          content.style.maxHeight = "0";
        }
      });
    });
  });

  return (
    <Styles>
      {/* Faq & Event */}
      <section className="event-faq-area">
        <Container style={{ maxWidth: "90%" }}>
          <Row>
            <Col md="6">
              <h5 className="m-5 text-dark">National Education Policy {"(NEP-2020)"} System of Schooling.</h5>
              <div className="event-area">
                <Row>
                  <Col md="12">
                    {Datas.eventDataList.map((eventData, i) => (
                      <div className="event-box d-flex" key={i}>
                        <div className="event-date text-center">
                          <p>{eventData.eventDate}</p>
                        </div>
                        <div className="event-details">
                          <h6>{eventData.eventTitle}</h6>
                          <p>{eventData.eventdesc}</p>
                        </div>
                      </div>
                    ))}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md="6">
              <h5 className="m-5 text-dark">Frequently Asked Questions</h5>
              <div className="faq-area">
                <div className="faq-box">
                  {Datas.faqDataList.map((faqData, i) => (
                    <div className="faq-item" key={i}>
                      <button className="accordion-button active">
                        <div className="accordion-icon">
                          <i className="fa-solid fa-plus dnone"></i>
                          <i className="fa-solid fa-minus"></i>
                        </div>
                        <p>{faqData.faqTitle}</p>
                      </button>
                      <div className="accordion-content show">
                        <p>{faqData.faqDesc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default FaqEvent;
