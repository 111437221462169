import React, { useEffect } from "react";
import NavBar from "../NavBar";
import { useState, useRef } from "react";
import "./Styles/Form.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import { useHistory } from "react-router-dom";

const ContentUpdate = () => {
  const [subMenuDetails, setSubMenuDetails] = useState([]);
  const { id } = useParams();
  const [values, setValues] = useState({
    _id: id,
    subMenu_id: "",
    contentHeading: "",
    file: "",
    contentDiscription: "",
  });

  const editor = useRef(null);

  const [desc, setDesc] = useState("");
  const [details, setDetails] = useState("");

  // Get the data of the Sub Menu
  console.log(values.contentImage);

  const handleSubMenuDetails = async () => {
    try {
      axios
        .get("https://chandrodaymsmd.org:5500/chandrodaymsmd/menu/sub")
        .then((result) => setSubMenuDetails(result.data))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  const handleDescription = async (desc) => {
    fetch(`https://chandrodaymsmd.org:5500/${desc}`)
      .then((res) => {
        return res.text();
      })
      .then((data) => setDetails(data))
      .catch((err) => console.log(err));
  };

  // Get Content Detailes
  useEffect(() => {
    axios
      .get(`https://chandrodaymsmd.org:5500/chandrodaymsmd/content/getContent/${id}`)
      .then((res) => {
        setValues({
          ...values,
          subMenu_id: res.data.data.subMenu_id,
          contentHeading: res.data.data.contentHeading,
          file: res.data.data.contentImage,
        });
        setDesc(res.data.data.contentDiscription);
      })
      .catch((err) => console.log(err));
    handleSubMenuDetails();
    handleDescription(desc);
  }, [desc]);

  // Update Content Detailes

  const navigate = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("subMenu_id", values.subMenu_id);
    formData.append("contentHeading", values.contentHeading);
    formData.append("file", values.file);
    formData.append("contentDiscription", details);


    try {
      await axios.patch(`https://chandrodaymsmd.org:5500/chandrodaymsmd/content/update/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Data Added Successfully");
      navigate.push("/Home");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <NavBar />
      <div className="w-50 login-box mt-5">
        <form className="container form" onSubmit={handleSubmit}>
          <h3 className="login-title">Content-Form</h3>

          <div className="mb-3">
            <label for="exampleHeading" class="form-label">
              Select Sub-Menu
            </label>
            <select
              name="SubMenu"
              className="form-control"
              onChange={(e) => setValues({ ...values, subMenu_id: e.target.value })}
              required
            >
              <option value={values.submenuName}>Select Name</option>
              {subMenuDetails.map((data, i) => (
                <option
                  value={data._id}
                  key={i}
                  selected={data._id === values.subMenu_id}
                >
                  {data.submenuName}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-3">
            <label for="exampleInputHeading" class="form-label">
              Heading
            </label>
            {/* {console.log(values.contentHeading)} */}
            <input
              type="text"
              value={values.contentHeading}
              class="form-control"
              onChange={(e) =>
                setValues({ ...values, contentHeading: e.target.value })
              }
            />
          </div>

          <div className="mb-3">
            <label for="exampleInputImage" class="form-label">
              Image
            </label>
            <div>
              <input
                type="file"
                onChange={(e) =>
                  setValues({ ...values, file: e.target.files[0] })
                }
                required
              />
              <img width="65" src={`https://chandrodaymsmd.org:5500/${values.file}`} />
            </div>
          </div>

          <div className="mb-3">
            <label for="exampleInputDescription" class="form-label">
              Description
            </label>
            <JoditEditor
              ref={editor}
              value={details}
              onChange={(newContent) => setDetails(newContent)}
              style={{ height: "500px" }}
            />
            {/* <textarea type="text" value={details} class="form-control" onChange={(e) => setDetails(e.target.value)} required /> */}
          </div>

          <button type="submit" class="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContentUpdate;
