import React, { useState } from 'react'
import NavBar from '../NavBar'
import './Styles/Form.css'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

function Faculty() {

  const [inputData, setInputData] = useState({
    menu_id: "abcd",
    Name: "",
    file: "",
    Designation: "",
    Description: "",
    Qualification: "",
    Email: "",
  })

  const navigate = useHistory();


  // Submite the data of faculty
  const handleSubmmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("Name", inputData.Name);
    formData.append("Designation", inputData.Designation);
    formData.append("Description", inputData.Description);
    formData.append("Qualification", inputData.Qualification);
    formData.append("facultyName", inputData.facultyName);
    formData.append("file", inputData.file);
    formData.append("Email", inputData.Email);
    formData.append("menu_id", inputData.menu_id);

    try {
      await axios.post("https://chandrodaymsmd.org:5500/chandrodaymsmd/faculty/insert", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Data Added Successfully");
      navigate.push("/Home");
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div>
      <NavBar />
      <div className='w-50 login-box mt-5'>
        <form className='container form' onSubmit={handleSubmmit}>
          <h3 className='login-title'>Faculty-Form</h3>
          <div className="mb-3">
            <label for="exampleInputName" className="form-label">Name</label>
            <input type="text" className="form-control" id="exampleInputName"
              onChange={e => setInputData({ ...inputData, Name: e.target.value })} value={inputData.Name} required />
          </div>
          <div className="mb-3">
            <label for="exampleInputImage" className="form-label">Image</label>
            <div>
              <input type='file' name='file_upload' accept='image/*'
                onChange={e => setInputData({ ...inputData, file: e.target.files[0] })} required />
            </div>
          </div>
          <div className="mb-3">
            <label for="exampleInputDesignation" className="form-label">Designation</label>
            <input type="text" className="form-control" id="exampleInputDesignation"
              onChange={e => setInputData({ ...inputData, Designation: e.target.value })} value={inputData.Designation} required />
          </div>
          <div className="mb-3">
            <label for="exampleInputDescription" className="form-label">Description</label>
            <textarea type="text" className="form-control" id="exampleInputPassword1"
              onChange={e => setInputData({ ...inputData, Description: e.target.value })} required />
          </div>
          <div className="mb-3">
            <label for="exampleInputQualification" className="form-label">Qualification</label>
            <input type="text" className="form-control" id="exampleInputDescription"
              onChange={e => setInputData({ ...inputData, Qualification: e.target.value })} required />
          </div>
          <div className="mb-3">
            <label for="exampleInputQualification" className="form-label">E-mail</label>
            <input type="email" className="form-control" id="exampleInputEmail"
              onChange={e => setInputData({ ...inputData, Email: e.target.value })} required />
          </div>
          <div className="mb-3">
            <div>
              <button type="submit" className="btn btn-primary" >Add</button>
            </div>
          </div>
        </form>
      </div>
    </div>

  )
}

export default Faculty