import React, { useEffect, useState, useRef } from "react";
import NavBar from "../NavBar";
import "./Styles/Form.css";
import axios from "axios";
import JoditEditor from "jodit-react";
import { useHistory } from "react-router-dom";

const ContentForm = ({ placeholder }) => {
  const [subMenuDetails, setSubMenuDetails] = useState([]);
  const [subMenuId, setSubMenuid] = useState("");
  const [heading, setHeading] = useState("");
  const [file, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [toggle, setToggle] = useState(true);
  const navigate = useHistory();

  const editor = useRef(null);

  // Get the data of the Sub Menu
  const handleMenuDetails = async () => {
    subMenuDetails.forEach(element => {
      if (element._id === subMenuId) {
        try {
          axios
            .get(`https://chandrodaymsmd.org:5500/chandrodaymsmd/menu/getMenu/${element.parent_id}`)
            .then((result) => {
              result.data.type ? result.data.type === "academics" ? setToggle(false) : setToggle(true) : setToggle(true);
            })
            .catch((err) => console.log(err));
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  // Get the data of the Sub Menu
  const handleSubMenuDetails = async () => {
    try {
      axios
        .get("https://chandrodaymsmd.org:5500/chandrodaymsmd/menu/sub")
        .then((result) => setSubMenuDetails(result.data))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  // Submite the data of the content
  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("subMenu_id", subMenuId);
    formData.append("contentHeading", heading);
    formData.append("file", file);
    formData.append("contentDiscription", description);

    try {
      await axios.post("https://chandrodaymsmd.org:5500/chandrodaymsmd/content/insert", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Data Added Successfully");
      navigate.push("/Home");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleMenuDetails();
  }, [subMenuId])

  useEffect(() => {
    handleSubMenuDetails();
  }, []);

  return (
    <div>
      <NavBar />
      <div className="w-50 login-box mt-5">
        <form className="container form" onSubmit={onSubmit}>
          <h3 className="login-title">Content-Form</h3>

          <div className="mb-3">
            <label for="exampleHeading" class="form-label">
              Select Sub-Menu
            </label>
            <select
              name="SubMenu"
              className="form-control"
              onChange={(e) => {
                setSubMenuid(e.target.value)
              }}
              required
            >
              <option value="">Select Name</option>
              {subMenuDetails.map((data, i) => {
                return (
                  <option value={data._id} key={i}>
                    {data.submenuName}
                  </option>
                )
              })}
            </select>
          </div>

          <div className="mb-3">
            <label for="exampleInputHeading" class="form-label">
              Heading
            </label>
            <input
              type="text"
              value={heading}
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={(e) => setHeading(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <label for="exampleInputImage" class="form-label">
              File
            </label>
            <div>
              <input
                type="file"
                onChange={(e) => {
                  setImage(e.target.files[0]);
                }}
                required
              />
            </div>
          </div>

          {toggle ?
            <div className="mb-3">
              <label for="exampleInputDescription" class="form-label">
                Description
              </label>
              <JoditEditor
                ref={editor}
                value={description}
                onChange={(newContent) => setDescription(newContent)}
                style={{ height: "500px" }}
              />
            </div>
            : <></>}

          <div className="mt-5 text-center">
            <button type="submit" class="btn btn-primary btn-lg">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContentForm;
