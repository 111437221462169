import React, { Component } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { Styles } from "./styles/breadcrumb.js";
import { Link } from 'react-router-dom';

export class BreadcrumbBox extends Component {

  state = {
    backgroundImage: "cover.jpg",
  };

  render() {
    // const menuName = window.location.href.split(":")[3];
    // var nMenuName = "";
    // try {
    //   nMenuName = menuName.split("%20");
    // } catch (error) {
    //   console.log(error);
    // }

    return (
      <Styles>
        <section
          className="breadcrumb-area"
          style={{
            backgroundImage: `url(/assets/cps_image/${this.state.backgroundImage})`,
          }}
        >
          <Container>
            <Row>
              <Col md="12" className="text-center">
                <div className="breadcrumb-box">
                  <h2 className="breadcrumb-title">
                    {/* {nMenuName.length > 1
                      ?
                      nMenuName.map((data) => { return (data + " ") })
                      : nMenuName.length === 1
                        ? menuName
                        : this.props.title} */}
                    {this.props.title}
                  </h2>
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      {/* {nMenuName.length > 1
                        ?
                        nMenuName.map((data) => { return (data + " ") })
                        : nMenuName.length === 1
                          ? menuName
                          : this.props.title} */}
                      {this.props.title}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    );
  }
}
