import React, { useMemo, useState } from "react";
// import Datas from "../../data/about-us/about-us.json";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./Style/moreDetails";
import axios from "axios";

function MoreDetails({ moreDetailsId }) {
  // console.log(moreDetailsId)
  const [moreDetails, setMoreDetails] = useState([]);
  const [extension, setExtension] = useState();
  console.log(extension);
  useMemo(async () => {
    await axios
      .get(
        `https://chandrodaymsmd.org:5500/chandrodaymsmd/content/getContent/${moreDetailsId}`
      )
      .then((result) => {
        // console.log(result.data.data.contentDiscription);
        DiscriptionTxt(result.data.data.contentDiscription);
        setMoreDetails(result.data.data);
        setExtension(result.data.data.contentImage.split(".")[1].toLowerCase());
      })
      .catch((err) => {
        console.log(err);
      });
  }, [moreDetailsId]);

  const DiscriptionTxt = async (contentDiscription) => {
    // console.log(contentDiscription);
    await fetch(`https://chandrodaymsmd.org:5500/${contentDiscription}`)
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        // console.log(data)
        const content = document.getElementById("aboutDetail");
        content.innerHTML = data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Styles>
      {/* About Us */}
      <section className="about-us">
        <Container style={{ maxWidth: "90%" }}>
          <Row>
            <Col md="7">
              <div className="about-content">
                <h4 className="about-title">{moreDetails.contentHeading}</h4>
                <p className="about-para" id="aboutDetail"></p>
              </div>
            </Col>
            <Col md="5">
              <div className="about-image">
                {/* <img
                  src={`https://chandrodaymsmd.org:5500/${moreDetails.contentImage}`}
                  className="main-img"
                  alt="Image"
                /> */}
                {extension == "jpeg" ||
                extension == "jpg" ||
                extension == "png" ? (
                  <img
                    src={`https://chandrodaymsmd.org:5500/${moreDetails.contentImage}`}
                    className="main-img"
                    alt="Image"
                  />
                ) : (
                  <>
                    <iframe
                      src={`https://chandrodaymsmd.org:5500/${moreDetails.contentImage}#toolbar=0&navpanes=0&scrollbar=0`}
                      className="iframeSettings"
                      frameBorder="0"
                      scrolling="no"
                    ></iframe>
                    <br />
                    <a
                      href={`https://chandrodaymsmd.org:5500/${moreDetails.contentImage}#toolbar=0&navpanes=0&scrollbar=0`}
                      className="btn btn-success"
                    >
                      View PDF
                    </a>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default MoreDetails;
