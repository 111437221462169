import React, { useEffect, useState } from 'react';
import NavBar from '../NavBar';
import './Styles/Form.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

function SuccessStoryForm() {

    const { id } = useParams();
    const [inputData, setInputData] = useState({
        Title: "",
        Author_Name: "",
        file: "",
        Author_Designation: "",
        Desc: ""
    })
    const navigate = useHistory();

    const handleSuccessStoryData = async () => {
        try {
            await axios.get(`https://chandrodaymsmd.org:5500/chandrodaymsmd/successStory/get/${id}`)
                .then((result) => {
                    setInputData({
                        ...inputData,
                        Title: result.data.Title,
                        Author_Name: result.data.Author_Name,
                        file: result.data.Author_Image,
                        Author_Designation: result.data.Author_Designation,
                        Desc: result.data.Desc

                    });
                })
                .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    }

    // Submite the data of Success Story
    const handleSubmmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("Title", inputData.Title);
        formData.append("Author_Name", inputData.Author_Name);
        formData.append("Author_Designation", inputData.Author_Designation);
        formData.append("Desc", inputData.Desc);
        formData.append("file", inputData.file);

        try {
            id
                ?
                await axios.patch(`https://chandrodaymsmd.org:5500/chandrodaymsmd/successStory/update/${id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                :
                await axios.post(`https://chandrodaymsmd.org:5500/chandrodaymsmd/successStory/insert`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
            alert("Data Saved Successfully");
            navigate.push("/Home");
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        handleSuccessStoryData();
    }, [id]);

    return (
        <div>
            <NavBar />
            <div className='w-50 login-box mt-5'>
                <form className='container form' onSubmit={handleSubmmit}>
                    <h3 className='login-title'>Success-Story-Form</h3>
                    <div className="mb-3">
                        <label for="exampleInputTitle" className="form-label">Title</label>
                        <input type="text" className="form-control" id="exampleInputTitle" placeholder="Enter title of your story"
                            onChange={e => setInputData({ ...inputData, Title: e.target.value })} value={inputData.Title} required />
                    </div>
                    <div className="mb-3">
                        <label for="exampleInputName" className="form-label">Author Name</label>
                        <input type="text" className="form-control" id="exampleInputName" placeholder="Enter Author Name"
                            onChange={e => setInputData({ ...inputData, Author_Name: e.target.value })} value={inputData.Author_Name} required />
                    </div>
                    <div className="mb-3">
                        <label for="exampleInputImage" className="form-label">Author Image</label>
                        <div>
                            <input type='file' name='file_upload' accept='image/*'
                                onChange={e => setInputData({ ...inputData, file: e.target.files[0] })} />
                            <img width="65" src={`https://chandrodaymsmd.org:5500/${inputData.file}`} />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label for="exampleInputDesignation" className="form-label">Author Designation</label>
                        <input type="text" className="form-control" id="exampleInputDesignation" placeholder="Enter Author Designation"
                            onChange={e => setInputData({ ...inputData, Author_Designation: e.target.value })} value={inputData.Author_Designation} required />
                    </div>
                    <div className="mb-3">
                        <label for="exampleInputDescription" className="form-label">Description</label>
                        <textarea type="text" className="form-control" id="exampleInputPassword1" placeholder="Enter Description"
                            onChange={e => setInputData({ ...inputData, Desc: e.target.value })} value={inputData.Desc} required />
                    </div>
                    <div className="mb-3">
                        <div className="text-center">
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SuccessStoryForm