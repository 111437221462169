import React, { Component } from "react";
// import Datas from "../../data/instructor/details.json";
import { Container, Row, Col } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Styles } from "./styles/instructor.js";
import Header from "../../components/Header.js"
import axios from "axios";

class InstructorDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fDetails: '',
      id: '',
    };
  }

  componentDidUpdate() {
    const Id = window.location.href.split('/')[4];

    axios.get(`https://chandrodaymsmd.org:5500/chandrodaymsmd/faculty/get/${Id}`)
      .then((result) => {
        console.log(result.data.data);
        this.setState({ fDetails: result.data.data, id: Id });
      })
      .catch((error) => {
        console.log(error)
      })
  }

  componentDidMount() {
    const Id = window.location.href.split('/')[4];

    axios.get(`https://chandrodaymsmd.org:5500/chandrodaymsmd/faculty/get/${Id}`)
      .then((result) => {
        console.log(result.data.data);
        this.setState({ fDetails: result.data.data, id: Id });
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {

    // console.log(this.state.fDetails)
    const { Name, Image, Designation, Contact = [], Qualification, Description } = this.state.fDetails;

    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper instructor-details-page">

          {/* Header */}
          <Header />

          {/* Breadcroumb */}
          <BreadcrumbBox title="Instructor Details" />

          {/* Instructor Details Area */}
          <section className="instructor-details-area">
            <Container>
              <Row>
                <Col md="4">
                  <div className="instructor-img">
                    <img
                      src={`https://chandrodaymsmd.org:5500/${Image}`}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Col>
                <Col md="8">
                  <div className="instructor-content">
                    <h4>{Name}</h4>
                    <span>{Designation}</span>
                    <p>
                      {Description}
                    </p>
                  </div>
                  <div className="qual-expe d-flex">
                    <div className="qualification">
                      <h5>Qualifications</h5>
                      <div className="qual-expe-box">
                        {/* <h6>University of California</h6> */}
                        <p>{Qualification}</p>
                      </div>
                      {/* <div className="qual-expe-box">
                        <h6>University of California</h6>
                        <p>Masters of Computer Science & Engeniering</p>
                      </div> */}
                    </div>
                    {/* <div className="experiance">
                      <h5>Experiance</h5>
                      <div className="qual-expe-box">
                        <h6>SnazzyTheme.com</h6>
                        <p>2016 - 2019</p>
                      </div>
                      <div className="qual-expe-box">
                        <h6>Envato Market</h6>
                        <p>2019 - Present</p>
                      </div>
                    </div> */}
                  </div>
                  <div className="qual-expe d-flex">
                    <div className="qualification">
                      <h5>Contact</h5>
                      <div className="qual-expe-box">
                        <p className="emailD"><i class="fa-regular fa-envelope"></i> {(Contact[0]?.Email)}</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </Styles >
    );
  }
}

export default InstructorDetails;
