import React, { useEffect, useState } from 'react'
import axios from 'axios'
import './Styles/Modal.css'

function MessageUpdate({ id, closeMessageModal }) {

    const [values, setValues] = useState({
        id: id,
        facultyName: "",
        message: "",
        image: "",
    });

    // Get Faculty Detailes
    useEffect(() => {
        axios.get("https://chandrodaymsmd.org:5500/chandrodaymsmd/msg/getMessage/" + id)
            .then(res => {
                // console.log(res.data),
                setValues({
                    ...values, facultyName: res.data.facultyName, message: res.data.message, image: res.data.image
                })
            })
            .catch(err => console.log(err))
    }, []);

    // Update Faculty Detailes
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("facultyName", values.facultyName);
        formData.append("message", values.message);
        formData.append("file", values.image);

        try {
            await axios.patch(`https://chandrodaymsmd.org:5500/chandrodaymsmd/msg/updateMessage/${values.id}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then(alert("Data Added Successfully"),
                window.location.reload());
        } catch (error) {
            console.log(error);
        }
    };

    function handleClose() {
        closeMessageModal()
    }

    useEffect(() => {
        document.body.style.overflowY = "hidden";
        return () => {
            document.body.style.overflowY = "scroll";
        }
    }, [])

    return (
        <>
            <div className="modal-wrapper"></div>
            <div className='w-50 login-box  mt-5 modal-container'>
                <div className='fa' onClick={handleClose}> &#xf00d;</div>
                <form className='container form' onSubmit={handleSubmit} encType="multipart/form-data">
                    <h3 className='login-title'>Message-Form</h3>
                    <div className="mb-3">
                        <label htmlFor="exampleInputName" className="form-label">Name</label>
                        <input type="text" className="form-control" id="exampleInputName"
                            value={values.facultyName} onChange={e => setValues({ ...values, facultyName: e.target.value })} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleInputImage" className="form-label">Image</label>
                        <div>
                            <input type='file' name='file' accept='image/*'
                                onChange={e => setValues({ ...values, image: e.target.files[0] })} />
                            <img width="65" src={`https://chandrodaymsmd.org:5500/${values.image}`} />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleInputMessage" className="form-label">Message</label>
                        <textarea className="form-control" id="exampleInputMessage"
                            value={values.message} onChange={e => setValues({ ...values, message: e.target.value })} required></textarea>
                    </div>
                    <div className="mb-3">
                        <div>
                            <button type="submit" className="btn btn-success" >Update</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default MessageUpdate