import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/aboutUs.js";
import Image from "../image/buildingCps.png"

const isOpenStyle = {
  WebkitLineClamp: 15,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  display: '-webkit-box'
}

function AboutUs() {

  const [isOpen, setIsOpen] = useState(false);

  return (

    <Styles>
      {/* About Us */}
      <section className="about-us">
        <Container style={{ maxWidth: "90%" }}>
          <Row>
            <Col md="6">
              <div className="about-image">
                <img
                  src={Image}
                  className="main-img"
                  alt="Image"
                />
              </div>
            </Col>
            <Col md="6">
              <div className="about-content">
                <h4 className="about-title">Welcome to Chandroday Public School, Mahasamund</h4>
                <p className="about-para">
                  <span style={isOpen ? null : isOpenStyle}>Chandroday Public School, established in 2017, is a distinguished educational institution situated at the prime location and heart of Mahasamund district in Chhattisgarh. It proudly stands as a co-educational, unaided, English medium school, affiliated with the prestigious Central Board of Secondary Education (C.B.S.E.), New Delhi, under the nurturing guidance of Chhattisgarh Chandranahu Shikshan Samiti, Mahasamund.
                    Driven by the resolute motto of "Excellence and Diligence," the school is wholeheartedly committed to providing a conducive learning environment for every child that enters its doors. Since its inception, Chandroday Public School has dedicated itself to raising the bar of education by imparting quality teaching and a keen focus on tangible learning outcomes.
                    At Chandroday Public School, education goes beyond textbooks and classrooms. The institution takes pride in its unique three-folded mission, which shapes the educational journey of its students:<br /><br />
                    - Elevating Education Standards: The school endeavors to provide modern, high-standard education in English medium, catering to the dynamic needs of the present times and preparing students for a globally interconnected world.<br />
                    - Fostering Personality Development: Recognizing the importance of nurturing well-rounded individuals, the school focuses on developing personality traits, instilling confidence, and fostering leadership qualities in each student.<br />
                    - Enriching, Empowering, and Enlightening: Chandroday Public School believes in enriching the minds of its students not only through academic knowledge but also through social awareness, value-based teachings, and spiritual wisdom, empowering them to be compassionate and responsible citizens.
                    In its pursuit of educational excellence, Chandroday Public School continues to be a guiding light for its students, illuminating the path to success and igniting the passion for lifelong learning.</span>
                </p>
                <a onClick={() => setIsOpen(!isOpen)} className="readmore-btn">{isOpen ? "Read Less" : "Read More"}</a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default AboutUs;
