import styled from "styled-components";

export const Styles = styled.div`
  .gallery-page {
    .gallery-page-area {
      padding: 70px 0;
      .gallery-box {
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 30px;
        position: relative;
        img {
          transition: all 0.3s ease;
          // max-height: 26rem !important;
          &:hover {
            transform: scale(1.1);
          }
        }
        .gallery-description {
          position: absolute;
          color: #fff;
          background-color: rgba(0,0,0,0.6);
          z-index: 1010;
          bottom: 13px;
          width: 100%;
          text-align: center;
        }
      }

      @media (max-width: 767px) {
        padding: 30px 0;
      }
    }
  }
`;
