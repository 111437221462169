import React, { useEffect, useState } from "react";
import { HeaderStyles } from "../components/styles/header";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authAction } from "../Redux/Store";
import axios from "axios";

function TopBar() {
  const dispatch = useDispatch();
  const { curUser, isLoggedIn } = useSelector((state) => state.user);
  const userName = curUser;
  const [thought, setThought] = useState([]);

  const userID = localStorage.getItem("userId");

  async function fetchData() {
    if (userID) {
      await axios
        .get(`https://chandrodaymsmd.org:5500/chandrodaymsmd/user/find/${userID}`)
        .then((result) => {
          // console.log(result.data);
          dispatch(authAction.login(result.data));
        })
        .catch((err) => console.log(err));
    }
  }

  function logOut() {
    dispatch(authAction.logout());
    window.location.href = "/";
  }

  const handleThoughts = async () => {
    try {
      await axios
        .get(`https://chandrodaymsmd.org:5500/chandrodaymsmd/thought/get/`)
        .then((result) => {
          // console.log(result.data.data);
          setThought(result.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleThoughts();
    fetchData();
  }, [])

  return (
    <>
      <HeaderStyles>
        {/* Topbar */}
        <section className="top-bar">
          <Container style={{ maxWidth: "90%" }}>
            <Row>
              <Col lg="2" md="3" style={{ width: "160px" }}>
                <div className="bar-left">
                  <ul className="list-unstyled list-inline">
                    <li
                      className="list-inline-item"
                      style={{ color: "#ffffff" }}
                    >
                      <a
                        href="https://goo.gl/maps/mYe99yienq12mYBKA"
                        target="_blank"
                      >
                        <i className="fa-solid fa-location-dot"></i>
                        Mahasamund
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="4" style={{ flexGrow: 1 }}>
                <div className="bar-center">
                  <marquee className="thought">
                    Welcome To Chandroday Public School || Thought of the day : {thought.map((data) => {
                      return data.thought
                    })}
                  </marquee>
                </div>
              </Col>
              <Col lg="4" md="5" style={{ width: "360px" }}>
                <div className="bar-right d-flex justify-content-end">
                  <ul className="list-unstyled list-inline bar-social">
                    <li className="list-inline-item">
                      <a
                        href={
                          "https://www.facebook.com/people/Chandroday-Public-School/100063768079557/"
                        }
                        target="_blank"
                      >
                        <i className="fa-brands fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href={"https://twitter.com/ChandrodayPubl1"}
                        target="_blank"
                      >
                        <i className="fa-brands fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href={
                          "https://www.linkedin.com/in/chandroday-public-school-mahasamund-8357b01aa/"
                        }
                        target="_blank"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href={"https://www.instagram.com/cpsmsmd722/"}
                        target="_blank"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>

                  {!userID ? (
                    <ul className="list-unstyled list-inline bar-login">
                      <li className="list-inline-item">
                        <Link to={"/login"}>
                          <i className="fa-solid fa-user"></i>Log In
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    <ul className="list-unstyled list-inline bar-login">
                      <Link to="/home" style={{ color: "#ffffff" }}>
                        <li className="list-inline-item">
                          <i
                            className="fa-regular fa-user"
                            style={{ color: "#11b67a" }}
                          ></i>
                          {"  " + userName}
                        </li>
                      </Link>
                      <li className="list-inline-item">
                        <Button onClick={logOut}>
                          <i className="las la-user-edit" />
                          Logout
                        </Button>
                      </li>
                    </ul>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </HeaderStyles>
    </>
  );
}

export default TopBar;
