import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Styles/Modal.css";

const ContentDec = ({ id, closeContent }) => {
  const [desc, setDesc] = useState("");
  const [details, setDetails] = useState("");

  function handleClose() {
    closeContent();
  }

  const handleDescription = async (desc) => {
    await fetch(`https://chandrodaymsmd.org:5500/${desc}`)
      .then((res) => {
        return res.text();
      })
      .then((data) => (document.querySelector("#desc").innerHTML = data))
      .catch((err) => console.log(err));
  };

  console.log(details);

  useEffect(() => {
    axios
      .get(
        `https://chandrodaymsmd.org:5500/chandrodaymsmd/content/getContent/${id}`
      )
      .then((res) => {
        setDesc(res.data.data.contentDiscription);
      })
      .catch((err) => console.log(err));
    handleDescription(desc);
  }, [desc]);

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="w-50 login-box  mt-5 modal-container">
        <div className="mb-3">
          <div
            className="fa"
            onClick={handleClose}
          >
            {" "}
            &#xf00d;
          </div>
          <h3 className="login-title">Description</h3>
          <div id="desc"></div>
        </div>
      </div>
    </>
  );
};

export default ContentDec;
