import React, { Component } from "react";
// import Datas from "../data/footer/footer.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import BackToTop from "./common/BackToTop";
import { Styles } from "./styles/footerOne.js";

class Footer extends Component {

  render() {
    return (
      <Styles>
        {/* Footer Area */}
        <footer
          className="footer1"
          style={{
            // backgroundImage: `url(assets/images/${Datas.backgroundImage})`,
            backgroundColor: '#182B49'
          }}
        >
          <Container style={{ maxWidth: "90%" }}>
            <Row>
              <Col md="5">
                <div className="footer-logo-info">
                  <h5>Stay in Touch</h5>
                  <p>
                    Chandroday Public School is situated at the core of
                    Mahasamund district of Chhattisgarh. It is a Co-Educational,
                    C.B.S.E. Pattern English Medium School.
                  </p>
                  <ul className="list-unstyled">
                    <li>
                      <i className="las la-map-marker"></i>Chandroday Public
                      School, Bagbahara Road, Mahasamund, Chhattisgarh, India
                    </li>
                    <li>
                      <i className="las la-phone"></i>(+91) 722 493 6878
                      <br />
                      <i className="las la-phone"></i>(+91) 07723-299349
                    </li>
                    <li>
                      <i className="las la-envelope"></i>
                      chandrodaypublicschool@gmail.com
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="3">
                <div className="f-links">
                  <h5>Useful Links</h5>
                  <ul className="list-unstyled">
                    <li>
                      <a href={"https://www.cbse.gov.in/"} target="_blank">
                        <i className="fa-solid fa-angle-right"></i>C.B.S.E.
                      </a>
                    </li>
                    <li>
                      <a href={"https://ncert.nic.in/"} target="_blank">
                        <i className="fa-solid fa-angle-right"></i>N.C.E.R.T.
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="4">
                <div className="f-post">
                  <h5>About School</h5>
                  <div className="post-box d-flex">
                    <div className="post-content">
                      Chandroday Public School is situated at the core of
                      Mahasamund district of Chhattisgarh. It is a Co-
                      Educational, C.B.S.E. Pattern English Medium School.
                      <span>Our Excellence</span>
                      <p>
                        6 Years of Excellence
                        <br />
                        CBSE Affiliation No. 3330391
                        <br />
                        School Code: 15941
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>

        {/* Copyright Area */}
        <section section className="copyright-area" >
          <Container style={{ maxWidth: "90%" }}>
            <Row>
              <Col md="4">
                <div className="copy-text text-left">
                  <p>
                    Designed With{" "}
                    <i class="fa-solid fa-heart"></i> by{" "}
                    <a href={"https://prixso.com/"} target="_blank" rel="noopener noreferrer">
                      Prixso Software
                    </a>
                  </p>
                </div>
              </Col>
              <Col md="4">
                <div className="copy-text text-center text-light">
                  Visiter Count: &nbsp;
                  <a href="https://www.hitwebcounter.com" target="_blank">
                    <img src="https://hitwebcounter.com/counter/counter.php?page=8805428&style=0006&nbdigits=5&type=ip&initCount=0" title="Counter Widget" alt="Counter" border="0" /></a>

                </div>
              </Col>
              <Col md="4" className="copy-text text-center text-right">
                <ul className="social list-unstyled list-inline">
                  <li className="list-inline-item">
                    <a
                      href={
                        "https://www.facebook.com/people/Chandroday-Public-School/100063768079557/"
                      }
                      target="blank"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href={"https://twitter.com/ChandrodayPubl1"}
                      target="blank"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href={
                        "https://www.linkedin.com/in/chandroday-public-school-mahasamund-8357b01aa/"
                      }
                      target="_blank"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={"https://www.instagram.com/cpsmsmd722/"}>
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href={"https://www.youtube.com/watch?v=r7sbHFvrGFs"}
                      target="_blank"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Back To Top */}
        <BackToTop />
      </Styles>
    );
  }
}

export default Footer;
