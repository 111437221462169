import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./helper/ScrollToTop";
import { GlobalStyle } from "./components/common/styles/global.js";
import HomeOne from "./HomeOne";
import Instructor from "./pages/instructor/Instructors";
import Login from "./pages/account/Login";
import Contact from "./pages/contact/Contact";
import PageNotFound from "./pages/404/PageNotFound";
import { useDispatch } from "react-redux";
import { authAction } from "./Redux/Store";
import axios from "axios";
import Common from "./pages/Common/Common";
import Academics from "./pages/academics/Academics";
import Footer from "./components/Footer";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Home from "./admin/Home.jsx";
import MenuForm from "./admin/Form/MenuForm";
import ContentForm from "./admin/Form/ContentForm";
import ContentUpdate from "./admin/Form/ContentUpdate";
import Faculty from "./admin/Form/Faculty";
import Slider from "./admin/Form/slider";
import SubMenuForm from "./admin/Form/SubMenuForm";
import Forget from "./pages/account/Forget";
import AboutData from "./components/AboutData";
import Message from "./admin/Form/Message";
import InstructorDetails from "./pages/instructor/InstructorDetails";
import Gallery from './pages/gallery/Gallery.js';
import GalleryForm from "./admin/Form/Gallery_Form";
import Notice from "./admin/Form/Notice";
import Thought from "./admin/Form/Thought";
import SuccessStory from "./admin/Form/SuccessStoryForm";

function App() {
  const userID = localStorage.getItem("userId");
  const dispatch = useDispatch();

  async function fetchData() {
    if (userID) {
      await axios
        .get(`https://chandrodaymsmd.org:5500/chandrodaymsmd/user/${userID}`)
        .then((result) => dispatch(authAction.login(result.data)))
        .catch((err) => console.log(err));
    }
  }

  useEffect(() => {
    fetchData();
  }, [userID]);

  return (
    <Router>
      <GlobalStyle />
      <ScrollToTop />
      {/* {console.log(userID)} */}
      {userID ? (
        <Switch>
          <Route exact path={"/"} component={HomeOne} />
          <Route path={"/common/:menuId"} component={Common} />
          <Route path={"/instructor/:menuId"} component={Instructor} />
          <Route path={"/instructorDetails/:Id"} component={InstructorDetails} />
          <Route path={"/aboutData/:Id"} component={AboutData} />
          <Route path={"/login"} component={Login} />
          <Route path={"/forget"} component={Forget} />
          <Route path={"/contact"} component={Contact} />
          <Route path={"/academics/:menuId"} component={Academics} />
          <Route path={"/gallery/:subMenuName?"} component={Gallery} />
          <Route path={"/Home"} component={Home} />
          <Route path={"/MenuForm"} component={MenuForm} />
          <Route path={"/SubMenuForm"} component={SubMenuForm} />
          <Route path={"/ContentForm"} component={ContentForm} />
          <Route path={"/ContentUpdate/:id"} component={ContentUpdate} />
          <Route path={"/Faculty"} component={Faculty} />
          <Route path={"/Notice"} component={Notice} />
          <Route path={"/Slider"} component={Slider} />
          <Route path={"/Message"} component={Message} />
          <Route path={"/GalleryForm"} component={GalleryForm} />
          <Route path={"/Thought"} component={Thought} />
          <Route path={"/SuccessStory/:id?"} component={SuccessStory} />
          <Route path={"*"} component={PageNotFound} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path={"/"} component={HomeOne} />
          <Route path={"/common/:menuId"} component={Common} />
          <Route path={"/aboutData/:Id"} component={AboutData} />
          <Route path={"/instructor/:menuId"} component={Instructor} />
          <Route path={"/instructorDetails/:Id"} component={InstructorDetails} />
          <Route path={"/login"} component={Login} />
          <Route path={"/contact"} component={Contact} />
          <Route path={"/academics/:menuId"} component={Academics} />
          <Route path={"/gallery/:subMenuName?"} component={Gallery} />
          <Route path={"*"} component={PageNotFound} />
        </Switch>
      )}

      <Footer />
    </Router>
  );
}

export default App;
