import React, { Component } from "react";
import AboutUs from "../../components/AboutUs";
import CourseFilter from "../../components/CourseFilter";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Header from "../../components/Header";

export default class Common extends Component {
  render() {
    return (
      <div className="main-wrapper">
        {/* Header */}
        <Header />

        {/* Breadcroumb */}
        <BreadcrumbBox title="About" />

        {/* Course Filter */}
        <CourseFilter />
      </div>
    );
  }
}
