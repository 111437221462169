import React, { useEffect, useState } from "react";
// import Datas from "../../data/instructor/instructor.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Styles } from "./styles/instructor.js";
import axios from "axios";
import Header from "../../components/Header";

function Instructor() {
  const [facultyData, setFacultyData] = useState([]);

  const handleFacultyData = async () => {
    axios
      .get(`https://chandrodaymsmd.org:5500/chandrodaymsmd/faculty/get`)
      .then((res) => {
        // console.log(res.data.data);
        setFacultyData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleFacultyData();
  }, []);

  return (
    <Styles>
      {/* Header */}
      <Header />

      {/* Main Wrapper */}
      <div className="main-wrapper instructor-page">
        {/* Breadcroumb */}
        <BreadcrumbBox title="Faculties" />

        {/* Instructor Area */}
        <section className="instructor-area">
          <Container style={{ maxWidth: "90%" }}>
            <Row>
              {facultyData.map((item, i) => (
                <Col xl="2" lg="3" md="4" sm="6" key={i}>
                  <div className="instructor-item">
                    <img
                      src={`https://chandrodaymsmd.org:5500/${item.Image}`}
                      alt={item.Image}
                      className="img-fluid"
                      style={{ height: '22rem', width: '100%' }}
                    />
                    <div className="img-content text-center">
                      <h5>
                        <Link to={`/instructorDetails/${item._id}`}>
                          {item.Name}
                        </Link>
                      </h5>
                      <p>{item.Designation}</p>
                    </div>
                  </div>
                </Col>
              ))}

              {/* <Col md="12" className="text-center">
                <Pagination />
              </Col> */}
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
}

export default Instructor;
